import { ExclamationCircleFilled, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { ProcessingAppListParams } from "@api/tryall/ProcessingApplicationApi";
import PageTitle from "@component/PageTitle";
import IntegratedTable from "@component/Table/IntegratedTable";
import {
  cancelProcessingApp,
  fetchProcessingAppList,
  ProcessingApplicationState,
  resetProcessingListParams,
  resetProcessingSlice,
  updateProcessingListParams,
} from "@redux/ProcessingApplicationSlice";
import { Button, Col, Form, message, Modal, Row, Select, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContentWrapper, FilterWrapper, FlexBox, Header } from "src/styles/common";
import styled from "styled-components";
import ProcessAppAddModal from "./ProcessAppAddModal";
import processingStatusOptions, { ProcessingStatus } from "./ProcessingStatus";

const TableWrapper = styled.div`
  .destroyed-row {
    color: rgba(189, 189, 189, 0.65);
  }
`;
const NoWrapTd = styled.span`
  white-space: nowrap;
`;

const ProcessingApplicationList = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { isFetching, listResult, listParam: processingListParams } = useSelector(ProcessingApplicationState);
  const { results: processingList, count: totalCount } = listResult;
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const processingListExcludeDestroyed = processingList.filter((item) => !item.destroyedAt);

  const handlePageOpen = (id: number) => {
    window.open(`/operation-of-brand/processing-application/${id}`, "_blank");
  };

  const showDeleteConfirm = (procNumber: string, id: number) => {
    confirm({
      title: "確定要作廢此筆資料？",
      icon: <ExclamationCircleFilled />,
      content: procNumber,
      okText: "確認",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        dispatch(cancelProcessingApp(id));
      },
    });
  };

  const tableColumns: ColumnsType<any> = [
    {
      key: "procNumber",
      dataIndex: "procNumber",
      title: "加工單編號",
    },
    {
      key: "estDeliveryDate",
      dataIndex: "estDeliveryDate",
      title: "預計完工日期",
      align: "center",
      width: 160,
    },

    {
      key: "status",
      dataIndex: "status",
      title: "加工單狀態",
      width: 160,
      align: "center",
      render: (value: number, record) => {
        if (record.destroyedAt) {
          return "已作廢";
        }
        return processingStatusOptions.find((status) => status.value === value)?.name || "";
      },
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "建立時間",
      align: "center",
      width: 160,
      render: (value: string) => <NoWrapTd>{value}</NoWrapTd>,
    },
    {
      key: "",
      dataIndex: "",
      title: "",
      render: (_: any, record) => (
        <FlexBox>
          <Button
            type="link"
            onClick={() => {
              if (record.id) handlePageOpen(record.id);
            }}
            disabled={record.destroyedAt}
          >
            檢視
          </Button>
          <Button
            type="link"
            onClick={() => {
              if (record.id) showDeleteConfirm(record.procNumber, record.id);
            }}
            disabled={
              record.destroyedAt ||
              (record.status !== ProcessingStatus.PENDING &&
                record.status !== ProcessingStatus.DEPLOYED &&
                record.status !== ProcessingStatus.READY)
            }
          >
            作廢
          </Button>
        </FlexBox>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchProcessingAppList());
  }, [dispatch, processingListParams]);

  useEffect(() => {
    return () => {
      dispatch(resetProcessingSlice());
    };
  }, [dispatch]);

  return (
    <ContentWrapper>
      <PageTitle title="加工單" />
      {showModal && <ProcessAppAddModal onClose={() => setShowModal(false)} />}
      <Header>
        <FlexBox>
          <Button type="primary" icon={<PlusCircleFilled />} onClick={() => setShowModal(true)}>
            新增加工單
          </Button>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </FlexBox>
      </Header>
      <ProcessingAppListFilter show={showFilter} />
      <TableWrapper>
        <IntegratedTable
          isPagination
          paginationConfig={{
            totalCount,
            updateFilterParams: (filter: ProcessingAppListParams) => dispatch(updateProcessingListParams(filter)),
            filterParams: processingListParams,
          }}
          dataSource={processingListParams.status ? processingListExcludeDestroyed : processingList}
          rowClassName={(record) => ((record as { destroyedAt: string }).destroyedAt ? "destroyed-row" : "")}
          columns={tableColumns}
          scroll={{ y: 500 }}
          tableLayout="auto"
          loading={isFetching}
          rowKey="id"
        />
      </TableWrapper>
    </ContentWrapper>
  );
};

export default ProcessingApplicationList;

const ProcessingAppListFilter = (props: { show: boolean }) => {
  const { show } = props;
  const dispatch = useDispatch();
  const { listParam: processingListParams } = useSelector(ProcessingApplicationState);
  const [form] = useForm();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFocus = () => {
    const showTags = form.getFieldValue("procNumbersQ")?.length > 0;
    setShowDropdown(showTags);
  };
  const onReset = () => {
    dispatch(resetProcessingListParams());
    form.resetFields();
  };
  const onSubmit = () => {
    const { procNumbersQ, status } = form.getFieldsValue();
    if (!procNumbersQ?.length && !status) {
      message.warning("請填入篩選資料");
      return;
    }
    const procNumberStr = procNumbersQ?.join(",");
    dispatch(
      updateProcessingListParams({
        ...processingListParams,
        procNumbersQ: procNumberStr,
        status,
      }),
    );
  };

  return (
    <FilterWrapper show={show}>
      <Form colon={false} labelAlign="left" form={form} initialValues={{}}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="加工單編號" name="procNumbersQ">
              <Select
                allowClear
                mode="tags"
                style={{ width: "100%" }}
                placeholder="可輸入多筆"
                open={showDropdown}
                onFocus={handleFocus}
                onBlur={() => setShowDropdown(false)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="加工單狀態" name="status">
              <Select allowClear>
                {processingStatusOptions.map((option) => (
                  <Select.Option key={option.name} value={option.value}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <FlexBox>
        <Space>
          <Button onClick={onReset}>清除篩選條件</Button>
          <Button type="primary" onClick={onSubmit}>
            套用
          </Button>
        </Space>
      </FlexBox>
    </FilterWrapper>
  );
};
