import React, { useState } from "react";
import { Button, Modal, Select, Col, Row, Space, Typography } from "antd";
import { MemberProgramType } from "@constant/MemberGroupValue";
import { CancelVIPMemberStatus, MemberListItem, VipCancelPriceCheck } from "@api/memberApi";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function ShowCancelVIPConfirm(props: {
  record: MemberListItem;
  priceCheck: VipCancelPriceCheck;
  onClose: () => void;
  onOk: (params: { id: number; status: CancelVIPMemberStatus }) => void;
}) {
  const { record, onClose, onOk, priceCheck } = props;
  const [cancelReasonId, setCancelReasonId] = useState(-1);

  function handleCancelReason(value: number) {
    setCancelReasonId(value);
  }
  const handleOk = () => {
    if (cancelReasonId !== -1) {
      const params = {
        id: record.id,
        status: cancelReasonId,
      };
      onOk(params);
      onClose();
    }
  };

  return (
    <Modal
      visible
      onOk={handleOk}
      onCancel={onClose}
      width={360}
      footer={
        <>
          <Row gutter={[4, 0]} justify="end">
            <Col style={{ textAlign: "center" }}>
              <Button onClick={onClose}>取消</Button>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <Button type="primary" onClick={handleOk} disabled={cancelReasonId === -1}>
                確定
              </Button>
            </Col>
          </Row>
        </>
      }
      maskClosable={false}
    >
      <Row gutter={[4, 12]}>
        <Col>
          <Space>
            <ExclamationCircleOutlined style={{ fontSize: "24px", color: "#faad14" }} />
            <Typography.Text style={{ fontSize: "20px" }} strong>
              請選擇取消原因
            </Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row gutter={[4, 12]}>
        <Col span={6}>會員名稱</Col>
        <Col>{record.name}</Col>
      </Row>
      <Row gutter={[4, 14]}>
        <Col span={6}>會員電話</Col>
        <Col>{record.phone}</Col>
      </Row>
      <Row gutter={[4, 0]}>
        <Select onChange={handleCancelReason} style={{ width: 180 }} defaultValue={-1}>
          <Option value={-1}>請選擇</Option>
          {!record.memberGroup.overDue && (
            <Option value={CancelVIPMemberStatus.NORMAL}>
              {record.membershipProgram?.programType === MemberProgramType.T_TRIAL ? "試用取消" : "合法取消"}
            </Option>
          )}
          <Option value={CancelVIPMemberStatus.VIOLATION}>違規取消</Option>
          {record.memberGroup.overDue && <Option value={CancelVIPMemberStatus.DISPUTES}>消費爭議</Option>}
        </Select>
      </Row>
      {cancelReasonId === CancelVIPMemberStatus.DISPUTES && priceCheck.price > 0 && (
        <Row style={{ color: priceCheck.price !== priceCheck.refundAmount ? "red" : "" }}>
          <Col>退還金額：</Col>
          <Col>{priceCheck.refundAmount}</Col>
        </Row>
      )}
    </Modal>
  );
}
