import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  financeOrderState,
  fetchFinanceOrderDetail,
  submitFinanceOrder,
  fetchOrderProducts,
  setProductsListFilter,
} from "@redux/financeOrderSlice";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { contractTypeMap, ProductsListFilter } from "@api/financeOrderApi";
import { StyledSection } from "src/styles/common";
import IntegratedTable from "@component/Table/IntegratedTable";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Text = styled.p``;
const Title = styled(Text)`
  font-weight: bold;
  padding-right: 20px;
`;

const Block = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const FinanceOrderDetail = () => {
  const dispatch = useDispatch();
  const { isDeatilFetching, financeOrderDetail, financeOrderProductResult, productsListFilter } = useSelector(
    financeOrderState,
  );
  const { orderId } = useParams();
  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
    },
    {
      title: "barcode",
      dataIndex: "barcode",
    },
    {
      title: "商品名稱",
      dataIndex: "name",
    },
    {
      title: "數量",
      dataIndex: "qty",
    },
  ];

  useEffect(() => {
    const payload = {
      orderId,
      ...productsListFilter,
    };
    dispatch(fetchOrderProducts(payload));
  }, [dispatch, orderId, productsListFilter]);

  useEffect(() => {
    dispatch(fetchFinanceOrderDetail(orderId));
  }, [dispatch, orderId]);

  const handleSubmit = () => {
    dispatch(submitFinanceOrder(orderId));
  };

  return (
    <StyledSection>
      <Header>
        <div>
          <Block>
            <Title>類型</Title>
            <Text>{contractTypeMap[financeOrderDetail.contractType]}</Text>
          </Block>
          <Block>
            <Title>供應／經銷商</Title>
            <Text>{financeOrderDetail.retailerName}</Text>
          </Block>
        </div>
        <Button onClick={handleSubmit} disabled={!!financeOrderDetail.submittedAt || !!financeOrderDetail.destroyedAt}>
          提交
        </Button>
      </Header>
      <IntegratedTable
        isPagination
        paginationConfig={{
          totalCount: financeOrderProductResult.count,
          updateFilterParams: (filter: ProductsListFilter) => dispatch(setProductsListFilter(filter)),
          filterParams: productsListFilter,
        }}
        loading={isDeatilFetching}
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={financeOrderProductResult.results}
      />
    </StyledSection>
  );
};

export default FinanceOrderDetail;
