import React, { useMemo } from "react";
import { ReviewStateByPositionLabel } from "@constant/ReviewState";
import { Col, Modal, Typography } from "antd";
import styled from "styled-components";
import { OrderStatusLabel } from "@constant/OrderStatus";
import { ReturnOrderDetail } from "./interfaces";
import ReviewStateTable from "./RevieStateTabel";

const NoticeText = styled.a`
  display: block;
  margin-top: 5px;
`;
const Wrapper = styled.div`
  padding-left: 16px;
  margin-top: 10px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  margin-bottom: 20px;
  display: flex;
`;
const RowTitle = styled.span`
  margin-right: 30px;
  width: 100px;
`;

export default function OrderDetails(props: { orderDetail: ReturnOrderDetail }) {
  const { orderDetail } = props;
  const { destWarehouseCode, purchaseNumber, purchaseId } = orderDetail;

  const info = () => {
    Modal.info({
      icon: null,
      okText: "我知道了",
      content: (
        <div>
          <FlexColumn>
            <Typography.Title level={5}>審核規則說明</Typography.Title>
            <Typography.Text strong>主管級送出申請的審核規則</Typography.Text>
            <Typography.Text>1. 3000 元內：不須任何人審核</Typography.Text>
            <Typography.Paragraph>2. 3000 元以上：送出申請後，需 CEO / 財務進行審核</Typography.Paragraph>
            <Typography.Text strong>非主管級送出申請的審核規則</Typography.Text>
            <Typography.Text>1. 3000 元內：送出申請後，一／二階主管審核</Typography.Text>
            <Typography.Text>
              1. 3000 元以上：送出申請後，除需一／二階主管審核之外， CEO / 財務也需要審核
            </Typography.Text>
          </FlexColumn>
        </div>
      ),
      onOk() {},
    });
  };

  const tableData = {
    ceo: orderDetail.ceoApproval,
    manager: orderDetail.managerApproval,
    sectionManager: orderDetail.sectionManagerApproval,
    finance: orderDetail.financeApproval,
  };

  const linkContent = useMemo(() => {
    // 入庫倉庫為藍田 開品運進倉單 /operation-of-brand/into-warehouse/<pk>
    // 入庫倉庫為黑貓 開平台進倉單 /into-warehouse/<pk>
    if (destWarehouseCode === "STORE") {
      return <span>{purchaseNumber}</span>;
    }

    let url;
    if (destWarehouseCode === "RENTRAP") {
      url = `/operation-of-brand/into-warehouse/${purchaseId}`;
    } else if (destWarehouseCode === "TCAT") {
      url = `/into-warehouse/${purchaseId}`;
    } else {
      url = null;
    }

    return <span>{purchaseNumber && url ? <a href={url}>{purchaseNumber}</a> : "N/A"}</span>;
  }, [destWarehouseCode, purchaseId, purchaseNumber]);

  return (
    <Wrapper>
      <Row>
        <RowTitle>訂單編號</RowTitle>
        <span>{orderDetail.orderNumber}</span>
      </Row>
      <Row>
        <RowTitle>審核狀態</RowTitle>
        <Col span={12}>
          {ReviewStateByPositionLabel[orderDetail.approvalStatus]}
          <NoticeText onClick={info}>審核規則說明</NoticeText>
          <ReviewStateTable data={tableData} />
        </Col>
      </Row>
      <Row>
        <RowTitle>建立人員</RowTitle>
        <span>{orderDetail.staffName}</span>
      </Row>
      <Row>
        <RowTitle>建立時間</RowTitle>
        <span>{orderDetail.createdAt}</span>
      </Row>
      <Row>
        <RowTitle>訂單狀態</RowTitle>
        <span>{OrderStatusLabel[orderDetail.status]}</span>
      </Row>
      <Row>
        <RowTitle>出庫倉庫</RowTitle>
        <span>{orderDetail.sourceWarehouseName}</span>
      </Row>
      <Row>
        <RowTitle>預計拋單日</RowTitle>
        <span>{orderDetail.estDeployedDate}</span>
      </Row>
      <Row>
        <RowTitle>預計到貨日</RowTitle>
        <span>{orderDetail.estArrivedDate}</span>
      </Row>
      <Row>
        <RowTitle>運送方式</RowTitle>
        <span>{orderDetail.carrierRelationName}</span>
      </Row>
      <Row>
        <RowTitle>入庫倉庫</RowTitle>
        <span>{orderDetail.destWarehouseName}</span>
      </Row>
      <Row>
        <RowTitle>進倉單號</RowTitle>
        {linkContent}
      </Row>
      <Row>
        <RowTitle>收件人</RowTitle>
        <span>{orderDetail.receiverName}</span>
      </Row>
      <Row>
        <RowTitle>收件電話</RowTitle>
        <span>{orderDetail.receiverPhone}</span>
      </Row>
      <Row>
        <RowTitle>收件地址</RowTitle>
        <span>{orderDetail.receiverAddress}</span>
      </Row>
      <Row>
        <RowTitle>給倉庫的備註</RowTitle>
        <span>{orderDetail.note}</span>
      </Row>
      <Row>
        <RowTitle>備註</RowTitle>
        <span>{orderDetail.desc}</span>
      </Row>
      <Row>
        <RowTitle>實際出貨時間</RowTitle>
        <span>{orderDetail.shippingAt}</span>
      </Row>
      <Row>
        <RowTitle>運送編號</RowTitle>
        <span>{orderDetail.shipperNumber}</span>
      </Row>
    </Wrapper>
  );
}
