import inventorySheetApi, {
  ImportInventorySheetInfo,
  InventoryProductInfo,
  InventorySheetDetail,
  InventorySheetItem,
  InventorySheetListParams,
} from "@api/sharing/inventorySheetApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import { ListResponseType } from "src/types";
import { message } from "antd";
import translateErrors from "@page/ThroughShipment/OnlineOrder/utils/translateErrors";

interface IState {
  isFetching: boolean;
  inventorySheetList: ListResponseType<InventorySheetItem>;
  inventorySheetListParams: InventorySheetListParams;
  inventorySheetDetail?: InventorySheetDetail;
  importErrorResults?: string[];
  InventorySheetProducts: ListResponseType<InventoryProductInfo>;
}
const initialState: IState = {
  isFetching: false,
  inventorySheetList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  inventorySheetListParams: {
    limit: 20,
    offset: 0,
  },
  inventorySheetDetail: undefined,
  importErrorResults: undefined,
  InventorySheetProducts: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
};

export const fetchInventorySheetList = createAsyncThunk(
  "inventorySheet/fetchInventorySheetList",
  async (params: InventorySheetListParams, thunkApi) => {
    try {
      const response = await inventorySheetApi.fetchInventorySheetList(params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchInventorySheetDetail = createAsyncThunk(
  "inventorySheet/fetchInventorySheetDetail",
  async (inventoryId: number, thunkApi) => {
    try {
      const response = await inventorySheetApi.fetchInventorySheetDetail(inventoryId);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const importInventorySheet = createAsyncThunk(
  "inventorySheet/importInventorySheet",
  async (payload: { info: ImportInventorySheetInfo; file: File }, thunkApi) => {
    try {
      const {
        inventorySheetSlice: { inventorySheetListParams },
      } = thunkApi.getState() as RootState;
      const response = await inventorySheetApi.importInventorySheet(payload.info, payload.file);
      message.success("匯入成功");
      thunkApi.dispatch(fetchInventorySheetList(inventorySheetListParams));

      return response;
    } catch (error: any) {
      const { result } = error;
      thunkApi.dispatch(updateImportErrorResults(translateErrors(result)));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteInventorySheet = createAsyncThunk(
  "inventorySheet/deleteInventorySheet",
  async (inventoryId: number, thunkApi) => {
    try {
      const {
        inventorySheetSlice: { inventorySheetListParams },
      } = thunkApi.getState() as RootState;
      const response = await inventorySheetApi.deleteInventorySheet(inventoryId);
      message.success("作廢成功");
      thunkApi.dispatch(fetchInventorySheetList(inventorySheetListParams));
      return response;
    } catch (error) {
      message.error("作廢失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const SubmitInventorySheet = createAsyncThunk(
  "inventorySheet/SubmitInventorySheet",
  async (inventoryId: number, thunkApi) => {
    try {
      const response = await inventorySheetApi.SubmitInventorySheet(inventoryId);
      message.success("提交成功");
      thunkApi.dispatch(fetchInventorySheetDetail(inventoryId));
      return response;
    } catch (error) {
      message.error("提交失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchInventorySheetProducts = createAsyncThunk(
  "inventorySheet/fetchInventorySheetProducts",
  async (payload: { inventoryId: number; filter: { limit: number; offset: number } }, thunkApi) => {
    try {
      const response = await inventorySheetApi.fetchInventorySheetProducts(payload);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const inventorySheetSlice = createSlice({
  name: "inventorySheet",
  initialState,
  reducers: {
    resetSlice: () => initialState,
    updateListParams: (state, action) => {
      state.inventorySheetListParams = action.payload;
    },
    resetListParams: (state) => {
      state.inventorySheetListParams = initialState.inventorySheetListParams;
    },
    updateImportErrorResults: (state, action) => {
      state.importErrorResults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventorySheetList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchInventorySheetList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.inventorySheetList = action.payload;
    });
    builder.addCase(fetchInventorySheetList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchInventorySheetDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchInventorySheetDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.inventorySheetDetail = action.payload;
    });
    builder.addCase(fetchInventorySheetDetail.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchInventorySheetProducts.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchInventorySheetProducts.fulfilled, (state, action) => {
      state.isFetching = false;
      state.InventorySheetProducts = action.payload;
    });
    builder.addCase(fetchInventorySheetProducts.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const { resetSlice, updateListParams, resetListParams, updateImportErrorResults } = inventorySheetSlice.actions;
export default inventorySheetSlice.reducer;

export const inventorySheetState = (state: RootState) => state.inventorySheetSlice;
