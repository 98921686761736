import returnOrderApi, { ProductBarcodeInfo, ProductStockInfoList } from "@api/returnOrderApi";
import { ChargingMethod } from "@page/ReturnOrder/constant";
import {
  OrderParams,
  ReturnErrorResult,
  ReturnOrderProcessType,
  ReturnProductResult,
  CreateProduct,
  ReturnOrderDetail,
  CarrierList,
  UpdateProduct,
  CopyWithProductsParams,
  ReturnOrderListResult,
  WarehouseList,
  TableListParams,
} from "@page/ReturnOrder/interfaces";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Modal, notification } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  carrierList: CarrierList[];
  externalWarehouseList: WarehouseList[];
  internalWarehouseList: WarehouseList[];
  isFetching: boolean;
  returnOrderListFilter: ReturnOrderListFilter;
  returnOrderList: ReturnOrderListResult;
  createOrderId?: number;
  isUpdateDone: boolean;
  isApplicationSended: boolean;
  returnOrderDetail?: ReturnOrderDetail;
  errorResult?: ReturnErrorResult[];
  // 商品
  productListResult: ReturnProductResult;
  productFilter: TableListParams;
  isProductFetching: boolean;
  isProductInfoFetching: boolean;
  productBarcodeInfo: ProductBarcodeInfo;
  productStockInfo: ProductStockInfoList[];
}

export const initialState: IState = {
  carrierList: [],
  isFetching: false,
  isProductFetching: false,
  isProductInfoFetching: false,
  returnOrderListFilter: {
    limit: 20,
    offset: 0,
  },
  externalWarehouseList: [],
  internalWarehouseList: [],
  returnOrderList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  createOrderId: undefined,
  isUpdateDone: false,
  isApplicationSended: false,
  returnOrderDetail: undefined,
  errorResult: [],
  // 商品
  productListResult: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  productFilter: {
    limit: 20,
    offset: 0,
  },
  productBarcodeInfo: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  productStockInfo: [],
};

interface ReturnOrderListFilter {
  orderNumber?: string;
  sku?: string;
  status?: number;
  estDeployedDateAfter?: string;
  estDeployedDateBefore?: string;
  shippingDateAfter?: string;
  shippingDateBefore?: string;
  approvalStatus?: number;
  sourceWarehouseCode?: string;
  destWarehouseCode?: string;
  limit: number;
  offset: number;
}

export const fetchCarrierList = createAsyncThunk(
  "returnOrder/fetchCarrierList",
  async (sourceWarehouseCode: string, thunkApi) => {
    try {
      const response = await returnOrderApi.fetchCarrierList(sourceWarehouseCode);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const fetchExternalWarehouseList = createAsyncThunk(
  "returnOrder/fetchExternalWarehouseList",
  async (_, thunkApi) => {
    try {
      const response = await returnOrderApi.fetchWarehouseList(1);
      return response;
    } catch (error: any) {
      message.error(`無法取得倉庫列表 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const fetchInternalWarehouseList = createAsyncThunk(
  "returnOrder/fetchInternalWarehouseList",
  async (_, thunkApi) => {
    try {
      const response = await returnOrderApi.fetchWarehouseList(0);
      return response;
    } catch (error: any) {
      message.error(`無法取得倉庫列表 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchReturnOrderList = createAsyncThunk(
  "returnOrder/fetchReturnOrderList",
  async (payload: ReturnOrderListFilter, thunkApi) => {
    try {
      const orders = await returnOrderApi.fetchReturnOrderList(payload);
      return orders;
    } catch (err: any) {
      thunkApi.rejectWithValue(err.message);
      return err.message;
    }
  },
);

export const fetchDeleteReturnOrder = createAsyncThunk(
  "returnOrder/fetchDeleteReturnOrder",
  async (orderId: number, thunkApi) => {
    try {
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      await returnOrderApi.deleteReturnOrder(orderId);
      thunkApi.dispatch(fetchReturnOrderList(returnOrderSlice.returnOrderListFilter));
      message.success("作廢成功");
      return "Success";
    } catch (error: any) {
      message.error(`無法作廢還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchCreateReturnOrder = createAsyncThunk(
  "returnOrder/fetchCreateReturnOrder",
  async (params: OrderParams, thunkApi) => {
    try {
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      const response = await returnOrderApi.createReturnOrder(params);
      message.success("新增成功");
      window.open(`/return-inventory/edit/${response.id}`);
      thunkApi.dispatch(fetchReturnOrderList(returnOrderSlice.returnOrderListFilter));
      return response;
    } catch (error: any) {
      message.error(`無法建立還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const copyReturnOrder = createAsyncThunk(
  "returnOrder/copyReturnOrder ",
  async (params: OrderParams, thunkApi) => {
    try {
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      const response = await returnOrderApi.createReturnOrder(params);
      thunkApi.dispatch(fetchReturnOrderList(returnOrderSlice.returnOrderListFilter));
      message.success("複製成功");
      window.open(`/return-inventory/edit/${response.id}`);
      return response;
    } catch (error: any) {
      message.error(`無法複製還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const copyReturnOrderWithProducts = createAsyncThunk(
  "returnOrder/copyReturnOrderWithProducts",
  async (params: CopyWithProductsParams, thunkApi) => {
    try {
      const response = await returnOrderApi.copyReturnOrderWithProducts(params);
      if (response.resultError) {
        thunkApi.dispatch(setErrorResult(response.resultError));
      } else {
        message.success("複製成功");
        window.open(`/return-inventory/edit/${response.id}`);
      }
      return response.id;
    } catch (error: any) {
      message.error(`無法複製還貨單 ${error.message}`);
      thunkApi.dispatch(setErrorResult([]));
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateReturnOrder = createAsyncThunk(
  "returnOrder/fetchUpdateReturnOrder",
  async (params: OrderParams, thunkApi) => {
    try {
      const response = await returnOrderApi.updateReturnOrder(params);
      thunkApi.dispatch(fetchOrderDetail(params.returnOrderId));
      if (params.chargingMethod) returnOrderAlertForDistribution(params.chargingMethod);
      if (params.submitted) {
        message.success("您已成功送出申請");
      } else {
        message.success("編輯成功");
      }
      return response;
    } catch (error: any) {
      message.error(`無法編輯還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const applyOrApproveReturnOrder = createAsyncThunk(
  "returnOrder/applyOrApproveReturnOrder",
  async (params: { orderId: string | number; processType: ReturnOrderProcessType }, thunkApi) => {
    try {
      const response = await returnOrderApi.applyOrApproveReturnOrder(params.orderId, params.processType);
      thunkApi.dispatch(fetchOrderDetail(params.orderId));
      switch (params.processType) {
        case ReturnOrderProcessType.APPLY:
          message.success("送出申請成功");
          break;
        case ReturnOrderProcessType.APPROVE:
        case ReturnOrderProcessType.REJECT:
          notification.open({
            message: "審查完成",
          });
          break;
        default:
      }
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderDetail = createAsyncThunk("returnOrder/fetchOrderDetail", async (orderId: string | number) => {
  const response = await returnOrderApi.fetchReturnOrderDetail(orderId);
  return response;
});

// 商品
export const fetchProductList = createAsyncThunk(
  "returnOrder/fetchProductList",
  async (params: { orderId: string; filter: TableListParams }) => {
    const { orderId, filter } = params;
    const response = await returnOrderApi.fetchReturnOrderProductList(orderId, filter);
    return response;
  },
);
// 新增商品
export const fetchCreateProduct = createAsyncThunk(
  "returnOrder/fetchCreateProduct",
  async (payload: CreateProduct, thunkApi) => {
    try {
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      const { productFilter } = returnOrderSlice;
      const response = await returnOrderApi.addOrderProduct(payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchProductList({ orderId: payload.returnOrderId, filter: productFilter }));
      thunkApi.dispatch(fetchOrderDetail(payload.returnOrderId));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
// 修改商品
export const fetchUpdateProduct = createAsyncThunk(
  "returnOrder/fetchUpdateProduct",
  async (payload: UpdateProduct, thunkApi) => {
    try {
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      const { productFilter } = returnOrderSlice;
      const response = await returnOrderApi.updateReturnProduct(payload);
      message.success("編輯成功");
      thunkApi.dispatch(fetchProductList({ orderId: payload.returnOrderId, filter: productFilter }));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchDeleteProduct = createAsyncThunk(
  "returnOrder/fetchDeleteProduct",
  async (payload: { returnOrderId: string; productId: number }, thunkApi) => {
    try {
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      const { productFilter } = returnOrderSlice;
      await returnOrderApi.deleteOrderProduct(payload);
      thunkApi.dispatch(fetchProductList({ orderId: payload.returnOrderId, filter: productFilter }));
      thunkApi.dispatch(fetchOrderDetail(payload.returnOrderId));

      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchProductDetailBySku = createAsyncThunk(
  "returnOrder/fetchProductDetailBySku",
  async (payload: { sku: string; warehouse: string }) => {
    const response = await returnOrderApi.fetchProductBarcodeBySku(payload);
    return response;
  },
);

export const fetchProductStockBySku = createAsyncThunk(
  "returnOrder/fetchProductStockBySku",
  async (payload: { sku: string; warehouse: string }) => {
    const response = await returnOrderApi.fetchProductStockBySku(payload);
    return response;
  },
);

const returnOrderSlice = createSlice({
  name: "returnOrder",
  initialState,
  reducers: {
    resetReturnOrderSlice: () => initialState,
    setReturnOrderListFilter: (state, action) => {
      state.returnOrderListFilter = action.payload;
    },
    setProductFilter: (state, action) => {
      state.productFilter = action.payload;
    },
    setCreateOrderId: (state, action) => {
      state.createOrderId = action.payload;
    },
    setIsUpdateDone: (state, action) => {
      state.isUpdateDone = action.payload;
    },
    setErrorResult: (state, action) => {
      state.errorResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarrierList.fulfilled, (state, action) => {
      state.carrierList = action.payload;
    });
    builder.addCase(fetchReturnOrderList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturnOrderList.fulfilled, (state, action) => {
      state.returnOrderList = action.payload;
      state.isFetching = false;
      state.createOrderId = undefined;
    });
    builder.addCase(fetchReturnOrderList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchExternalWarehouseList.fulfilled, (state, action) => {
      state.externalWarehouseList = action.payload;
    });
    builder.addCase(fetchInternalWarehouseList.fulfilled, (state, action) => {
      state.internalWarehouseList = action.payload;
    });
    builder.addCase(fetchCreateReturnOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCreateReturnOrder.fulfilled, (state, action) => {
      state.createOrderId = action.payload.id;
      state.isFetching = false;
    });
    builder.addCase(fetchCreateReturnOrder.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateReturnOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateReturnOrder.fulfilled, (state, action) => {
      state.isUpdateDone = true;
      state.returnOrderDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateReturnOrder.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchOrderDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderDetail.fulfilled, (state, action) => {
      state.returnOrderDetail = action.payload;
      state.isUpdateDone = false;
      state.isFetching = false;
      state.createOrderId = undefined;
    });
    builder.addCase(fetchOrderDetail.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(copyReturnOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(copyReturnOrder.rejected, (state) => {
      state.isFetching = false;
    });
    // 商品
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.productListResult = action.payload;
    });
    builder.addCase(fetchProductDetailBySku.pending, (state, action) => {
      state.isProductInfoFetching = true;
    });
    builder.addCase(fetchProductDetailBySku.fulfilled, (state, action) => {
      state.productBarcodeInfo = action.payload;
      state.isProductInfoFetching = false;
    });
    builder.addCase(fetchProductStockBySku.pending, (state, action) => {
      state.isProductInfoFetching = true;
    });
    builder.addCase(fetchProductStockBySku.fulfilled, (state, action) => {
      state.productStockInfo = action.payload;
      state.isProductInfoFetching = false;
    });
  },
});

export const returnOrderState = (state: RootState) => state.returnOrderSlice;
export const {
  setReturnOrderListFilter,
  setCreateOrderId,
  setIsUpdateDone,
  resetReturnOrderSlice,
  setErrorResult,
  setProductFilter,
} = returnOrderSlice.actions;
export default returnOrderSlice.reducer;

function returnOrderAlertForDistribution(chargingMethod: number) {
  if (chargingMethod === ChargingMethod.CHARTER) {
    Modal.warning({
      title: "提醒您，請記得發信 & Line通知明錩正確配送方式！",
      okText: "我知道了",
    });
  }
}
