import { FetchVPCSalesListParams } from "@api/salesReportApi";
import tagApi from "@api/tagApi";
import { SalesChannelType } from "@api/vendorApi";
import SearchSelect, { ListParams } from "@component/Common/SearchSelect";
import { RootState } from "@redux/rootReducer";
import {
  clearVPCSaleListFilter,
  fetchBrandList,
  fetchProductCategoryList,
  fetchVPCSalesList,
  listParams,
  updateVPCSalesListParams,
} from "@redux/salesReportSlice";
import { Button, Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select, Space } from "antd";

import moment from "moment";
import React, { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 54px;
`;

const Filter: FC<{ form: FormInstance }> = ({ form }) => {
  const dispatch = useAppDispatch();
  const { bdStaffData } = useSelector((state: RootState) => state.staffs);
  const { VPCSalesListParams, productCategory } = useSelector((state: RootState) => state.salesReport);

  const categoryLevel1Value = Form.useWatch("categoryLevel1", form);
  const categoryLevel2Value = Form.useWatch("categoryLevel2", form);
  const formInitValues = {
    ...listParams,
    contractType: listParams.contractType?.split(","),
    includeGift: !listParams.includeGift,
    includeAddBuy: !listParams.includeAddBuy,
    salesChannel: VPCSalesListParams.salesChannel,
  };

  const categoryLevel1Options = useMemo(() => {
    const options = Object.values(productCategory.level1).map((ctgryItem) => ({
      label: ctgryItem.name,
      value: ctgryItem.id,
    }));
    return options;
  }, [productCategory]);

  const categoryLevel2Options = useMemo(() => {
    if (!categoryLevel1Value) return [];
    form.setFieldsValue({ categoryLevel3: undefined, categoryLevel2: undefined });
    const level2Options = productCategory.level1[categoryLevel1Value];
    const options = level2Options.children.map((ctgryItem) => ({
      label: ctgryItem.name,
      value: ctgryItem.id,
    }));
    return options;
  }, [categoryLevel1Value, form, productCategory.level1]);

  const categoryLevel3Options = useMemo(() => {
    if (!categoryLevel2Value) return [];
    form.setFieldsValue({ categoryLevel3: undefined });
    const level3Options = productCategory.level2[categoryLevel2Value];
    const options = level3Options.children.map((ctgryItem) => ({
      label: ctgryItem.name,
      value: ctgryItem.id,
    }));
    return options;
  }, [categoryLevel2Value, form, productCategory.level2]);

  const handleOnClear = () => {
    dispatch(clearVPCSaleListFilter());
    form.resetFields();
    form.setFieldsValue(formInitValues);
  };

  const handleOnApply = (e: React.FormEvent<HTMLFormElement>) => {
    const filterParams = getFilterParams(form, VPCSalesListParams.salesChannel);
    dispatch(updateVPCSalesListParams({ ...VPCSalesListParams, ...filterParams }));
    dispatch(fetchVPCSalesList());
  };

  useEffect(() => {
    dispatch(fetchBrandList(""));
    dispatch(fetchProductCategoryList());
    form.setFieldsValue(formInitValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Wrapper>
      <Form form={form} labelCol={{ span: 6 }} labelAlign="left" colon={false} onFinish={handleOnApply}>
        <Row gutter={[24, 0]}>
          <Col span={9}>
            <Form.Item name="owner" label="負責人">
              <Select
                mode="multiple"
                options={bdStaffData.map((stf) => ({
                  value: stf.id,
                  label: stf.name,
                }))}
                placeholder="請選擇"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="orderType" label="一般/黑卡折扣訂單" labelCol={{ span: 14 }} wrapperCol={{ span: 10 }}>
              <Select
                options={[
                  {
                    label: "全部",
                    value: "",
                  },
                  {
                    label: "一般",
                    value: "normal",
                  },
                  {
                    label: "黑卡",
                    value: "black",
                  },
                ]}
                placeholder="請選擇"
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="productNameQ" label="商品名稱">
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={9}>
            <Form.Item name="products" label="商品ID">
              <Select
                mode="tags"
                placeholder="可一次篩選多筆, 以逗號分隔"
                open={false}
                tokenSeparators={[",", "，", "\n"]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="brand" label="品牌">
              <SearchSelect
                fetchListApi={async (params: ListParams) => {
                  const response = tagApi.fetchTagList({ ...params, typeNameQ: "品牌" });
                  return response;
                }}
                optionsKey={{ label: "name", value: "id" }}
                onChange={(value) => {
                  form.setFieldsValue({ brand: value });
                }}
                mode="multiple"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={9}>
            <Form.Item name="skus" label="國際條碼">
              <Select
                mode="tags"
                placeholder="可一次篩選多筆, 以逗號分隔"
                open={false}
                tokenSeparators={[",", "，", "\n"]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="excludeSku" label="排除條碼">
              <Input placeholder="可一次篩選多筆, 以逗號分隔" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={9}>
            <Form.Item name="categoryLevel1" label="第一層品類">
              <Select
                placeholder="請選擇第一層品類"
                allowClear
                options={categoryLevel1Options}
                onClear={() => {
                  form.setFieldsValue({ categoryLevel3: undefined, categoryLevel2: undefined });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="vpcStartDate" label="開賣時間">
              <DatePicker.RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={9}>
            <Form.Item name="categoryLevel2" label="第二層品類">
              <Select
                placeholder="請先選擇第一層品類，再選擇第二層品類"
                allowClear
                options={categoryLevel2Options}
                disabled={!categoryLevel1Value}
                onClear={() => {
                  form.setFieldsValue({ categoryLevel3: undefined });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="orderCreateDate" label="銷售期限">
              <DatePicker.RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={9}>
            <Form.Item name="categoryLevel3" label="第三層品類" shouldUpdate>
              <Select
                placeholder="請先選擇第二層品類，再選擇第三層品類"
                allowClear
                options={categoryLevel3Options}
                disabled={!categoryLevel2Value}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="contractType" label="合約狀態">
              <Select
                mode="multiple"
                allowClear
                options={[
                  { label: "一般", value: "1" },
                  { label: "臨時", value: "2" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <Row>
              <Form.Item name="includeGift" valuePropName="checked">
                <Checkbox>不含贈品</Checkbox>
              </Form.Item>
              <Form.Item name="includeAddBuy" valuePropName="checked">
                <Checkbox>不含加購品</Checkbox>
              </Form.Item>
            </Row>
          </Col>
          <Col>
            <Row>
              <Space>
                <Button onClick={handleOnClear}>清除篩選條件</Button>
                <Button type="primary" htmlType="submit">
                  套用
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
};

export default Filter;

export const getFilterParams = (form: FormInstance, salesChannel?: SalesChannelType) => {
  const formData = form.getFieldsValue();
  const {
    orderCreateDate: orderDate,
    vpcStartDate: vpcDate,
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
  } = formData;
  // 找到最下層的category id
  const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);
  const filterParams: FetchVPCSalesListParams = {
    limit: 20,
    offset: 0,
    productNameQ: formData.productNameQ,
    owner: formData.owner?.join(),
    brand: formData.brand?.join(),
    skus: formData.skus?.map((sku: string) => sku.trim()).join(),
    products: formData.products?.join(),
    excludeSku: formData.excludeSku,
    orderCreatedDateAfter: orderDate && orderDate[0] ? moment(orderDate[0]).format("YYYY-MM-DD") : undefined,
    orderCreatedDateBefore: orderDate && orderDate[1] ? moment(orderDate[1]).format("YYYY-MM-DD") : undefined,
    vpcStartDateAfter: vpcDate && vpcDate[0] ? moment(vpcDate[0]).format("YYYY-MM-DD") : undefined,
    vpcStartDateBefore: vpcDate && vpcDate[1] ? moment(vpcDate[1]).format("YYYY-MM-DD") : undefined,
    includeGift: !formData.includeGift,
    includeAddBuy: !formData.includeAddBuy,
    category,
    contractType: formData.contractType?.join(),
    orderType: formData.orderType,
    salesChannel,
  };
  return filterParams;
};
