import React, { MouseEvent, useState, useEffect } from "react";
import MemberGroupValue, { memberGroupMap, MemberProgramTypeOptions } from "@constant/MemberGroupValue";
import GenderValue, { genderMap } from "@constant/GenderValue";
import { Button, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import memberApi, {
  CancelVIPMemberStatus,
  MemberListItem,
  VIPStatus,
  VipCancelPriceCheck,
  MemberFilterParams,
} from "@api/memberApi";
import {
  cancelVIPAutoRenewal,
  cancelVIPMember,
  updateMemberListParams,
  fetchMemberList,
  deleteAccount,
} from "@redux/memberSlice";
import { hideLoading, showLoading } from "@redux/notifySlice";
import { usePermission, StaffPermission } from "@hooks/usePermission";
import IntegratedTable from "@component/Table/IntegratedTable";
import { ColumnsType } from "antd/lib/table";
import DeleteAccountPopup from "./DeleteAccountPopup";
import ShowCancelVIPConfirm from "./ShowCancelVIPConfirm";

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function MemberList() {
  const { memberListResult, memberListParams, isFetchingMemberList } = useSelector((state: RootState) => state.member);
  const canModifyMembershipUnlimited = usePermission(StaffPermission.CAN_MODIFY_MEMBERSHIP_UNLIMITED);

  const dispatch = useAppDispatch();
  const [cancelVIPOpen, setCancelVIPOpen] = useState<MemberListItem>();
  const [priceCheck, setPriceCheck] = useState<VipCancelPriceCheck>({ price: -1, refundAmount: -1, memberId: -1 });
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [memberInfo, setMemberInfo] = useState({ name: "", phone: "", id: 0 });
  const { count, results: memberList } = memberListResult;

  function handleDeleteMemberAccount(record: MemberListItem) {
    if (record.memberGroup.id === MemberGroupValue.VIP) {
      message.error("目前該會員為黑卡會籍，請勿刪除帳號");
    } else {
      const payload = { id: record.id, checkOrder: true };
      dispatch(deleteAccount(payload));
      setOpenDeletePopup(true);
      setMemberInfo({
        name: record.name,
        phone: record.phone,
        id: record.id,
      });
    }
  }

  function handleCancelVIPMember(record: MemberListItem) {
    return async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      try {
        dispatch(showLoading());
        const resp = await memberApi.vipCancelPriceCheck(record.id);
        setCancelVIPOpen(record);
        setPriceCheck(resp);
        return "success";
      } catch (error: any) {
        return "error";
      } finally {
        dispatch(hideLoading());
      }
    };
  }
  function handleCancelVIPSubmit(params: { id: number; status: CancelVIPMemberStatus }) {
    dispatch(cancelVIPMember(params));
  }

  function cancelAutoRenewal(id: number) {
    Modal.confirm({
      title: "你確定要取消自動續約嗎?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (id) {
          dispatch(cancelVIPAutoRenewal(id));
        }
      },
      okText: "是",
      cancelText: "否",
    });
  }

  function handleCancelRenewal(id: number) {
    return (e: any) => {
      e.preventDefault();
      cancelAutoRenewal(id);
    };
  }

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 65,
    },
    {
      title: "會員名稱",
      dataIndex: "name",
      width: 120,
    },
    {
      title: "註冊時間",
      dataIndex: "createdAt",
      width: 191,
      render: (value: MemberListItem["createdAt"]) => value.substring(0, 19),
    },
    {
      title: "會員電話",
      dataIndex: "phone",
      width: 125,
    },
    {
      title: "會員信箱",
      dataIndex: "email",
      width: 180,
    },
    {
      title: "會員資格",
      dataIndex: ["memberGroup", "groupId"],
      render: (group: MemberGroupValue, data: MemberListItem) => {
        const { membershipProgram } = data;
        if (!membershipProgram) return <Text>{memberGroupMap[group]}</Text>;
        return (
          <Text>
            {MemberProgramTypeOptions.find((option) => option.value === membershipProgram.programType)?.label}
          </Text>
        );
      },
      width: 95,
    },
    {
      title: "性別",
      dataIndex: "gender",
      width: 65,
      render: (gender: GenderValue) => genderMap[gender],
    },
    {
      title: "生日",
      dataIndex: "birthday",
      width: 110,
    },
    {
      title: "取消原因",
      dataIndex: "status",
      width: 105,
      render: (status: VIPStatus) => {
        if (status === VIPStatus.TERMINATED) return "非法停權";
        if (status === VIPStatus.CANCELED) return "合法取消";
        if (status === VIPStatus.DISPUTES) return "消費爭議";
        return "N/A";
      },
    },
    {
      title: "",
      dataIndex: "id",
      render: (id: number, record: MemberListItem) => {
        if (record.memberGroup.groupId === MemberGroupValue.GENERAL)
          return (
            <CustomButton type="default" onClick={() => handleDeleteMemberAccount(record)}>
              刪除會員帳號
            </CustomButton>
          );
        return (
          <>
            <CustomButton type="default" onClick={() => handleDeleteMemberAccount(record)}>
              刪除會員帳號
            </CustomButton>
            <CustomButton
              type="default"
              onClick={handleCancelVIPMember(record)}
              disabled={canModifyMembershipUnlimited ? false : record.memberGroup.overDue}
            >
              取消黑卡資格
            </CustomButton>
            <Button type="default" onClick={handleCancelRenewal(id)} disabled={!record.autoRenewal}>
              取消自動續約
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchMemberList(memberListParams));
  }, [dispatch, memberListParams]);

  return (
    <>
      {openDeletePopup && <DeleteAccountPopup memberInfo={memberInfo} onClose={() => setOpenDeletePopup(false)} />}
      {cancelVIPOpen && (
        <ShowCancelVIPConfirm
          record={cancelVIPOpen}
          priceCheck={priceCheck}
          onClose={() => {
            setCancelVIPOpen(undefined);
          }}
          onOk={handleCancelVIPSubmit}
        />
      )}
      <IntegratedTable
        isPagination
        paginationConfig={{
          totalCount: count,
          updateFilterParams: (filter: MemberFilterParams) => dispatch(updateMemberListParams(filter)),
          filterParams: memberListParams,
        }}
        loading={isFetchingMemberList}
        columns={columns}
        dataSource={memberList}
      />
    </>
  );
}
