import React, { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { StyledOutlinedWrapper, StyledSection } from "src/styles/common";
import PageTitle from "@component/PageTitle";
import MemberFilterForm from "./MemberFilterForm";
import MemberList from "./MemberList";

export default function MemberListPage() {
  const [openFilterForm, setOpenFilterForm] = useState(false);

  function toggleFilter() {
    setOpenFilterForm((prev) => !prev);
  }

  return (
    <StyledSection>
      <PageTitle title="會員資料" />
      <Row style={{ marginBottom: 20 }} align="middle" justify="space-between">
        <Button icon={<FilterOutlined />} onClick={toggleFilter}>
          篩選
        </Button>
      </Row>
      {openFilterForm && <MemberFilterForm />}
      <StyledOutlinedWrapper>
        <MemberList />
      </StyledOutlinedWrapper>
    </StyledSection>
  );
}
