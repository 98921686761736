import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PopupBackground from "@component/PopupBackground";
import { Form, Typography, Button, Select } from "antd";
import { CreateSupplyChainRetailerParam } from "@api/supplyChainApi";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import {
  fetchRetailRegionList,
  fetchRetailList,
  fetchRetailerProductLineList,
  fetchContractList,
  fetchRetailerProductLineRelation,
  createSupplyChainRetailer,
} from "@redux/supplyChainSlice";
import { convertRegionType, RegionType } from "@api/throughShipment/throughShipmentApi";

interface Props {
  onClose: () => void;
}
const Footer = styled.div`
  position: relative;
`;

const PopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
`;

const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;
const TitleWrapper = styled.div`
  text-align: left;
`;
const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
`;
const Text = styled.div`
  text-align: left;
`;
const ContractText = styled(Text)`
  cursor: pointer;
  color: #1890ff;
`;
const ErrorText = styled.div`
  position: fixed;
  color: ${({ theme }) => theme.colorSecondary500};
`;

const { Title } = Typography;

const RetailerPopup: FC<Props> = (props) => {
  const { onClose } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { supplyChainId } = useParams();
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const retailRegionList = supplyChainSlice.retailRegionList.results;
  const retailList = supplyChainSlice.retailList.results;
  const retailerProductLine = supplyChainSlice.retailerProductLineList.results;
  const contractList = supplyChainSlice.contractList.results;
  const retailerProductLineRelationList = supplyChainSlice.retailerProductLineRelationList.results;

  const [regionTypeNumber, setRegionTypeNumber] = useState<number>();
  const [regionId, setRegionId] = useState<number>();
  const [retailerId, setRetailerId] = useState<number>();
  const [productLineNumber, setProductLineNumber] = useState<number>();
  const regionType = Form.useWatch("regionType", form);

  // 1.選完合作類型通路
  useEffect(() => {
    if (regionTypeNumber !== undefined) {
      dispatch(fetchRetailRegionList(regionTypeNumber));
    }
  }, [dispatch, regionTypeNumber]);

  // 2.選完地區
  useEffect(() => {
    if (regionId !== undefined) {
      dispatch(fetchRetailList(regionId));
    }
  }, [dispatch, regionId]);

  // 3.選完通路
  useEffect(() => {
    if (retailerId !== undefined) {
      dispatch(fetchRetailerProductLineList(retailerId));
    }
  }, [dispatch, retailerId]);

  // 4.選完線別
  useEffect(() => {
    const params = { retailerId, productLineNumber };
    if (params.retailerId !== undefined && params.productLineNumber !== undefined) {
      dispatch(fetchRetailerProductLineRelation(params));
    }
  }, [contractList, dispatch, productLineNumber, retailerId]);

  const handleRegionType = (e: number) => {
    setRegionTypeNumber(e);
    form.setFieldsValue({ regionId: undefined, retailer: undefined, productLine: undefined });
    setRegionId(undefined);
    setRetailerId(undefined);
    setProductLineNumber(undefined);
  };
  const handleRegionId = (e: number) => {
    setRegionId(e);
    form.setFieldsValue({ retailer: undefined, productLine: undefined });
    setRetailerId(undefined);
    setProductLineNumber(undefined);
  };
  const handleRetailerId = (e: number) => {
    setRetailerId(e);
    form.setFieldsValue({ productLine: undefined });
    setProductLineNumber(undefined);
  };
  const handleContract = (productLineId: number) => {
    setProductLineNumber(productLineId);
    const params = { retailerId, productLineId };
    dispatch(fetchContractList(params));
  };

  const handleOpenContract = (path: string | undefined) => {
    window.open(path, "_blank");
  };

  const handleOnSubmit = () => {
    const params: CreateSupplyChainRetailerParam = {
      retailerProductline: retailerProductLineRelationList[0].id,
      supplyChain: Number(supplyChainId),
    };
    dispatch(createSupplyChainRetailer(params));

    onClose();
  };

  useEffect(() => {
    if (retailRegionList[0] && regionType !== RegionType.FOREIGN) {
      form.setFieldsValue({ regionId: retailRegionList[0].id });
      setRegionId(retailRegionList[0].id);
    }
  }, [form, regionType, retailRegionList]);

  return (
    <PopupBackground close={onClose} fixed>
      <PopWrapper>
        <Form form={form} onFinish={handleOnSubmit} labelCol={{ flex: "120px" }} labelAlign="left" colon={false}>
          <TitleWrapper>
            <Title level={5}>選擇合作通路</Title>
          </TitleWrapper>
          <RowContent width={300}>
            <Form.Item label="合作通路類型" name="regionType" rules={[{ required: true, message: "" }]}>
              <StyledSelect
                placeholder="請選擇"
                onChange={(e) => handleRegionType(Number(e))}
                options={Object.values(RegionType)
                  .filter((value) => typeof value === "number") // 僅保留數字值
                  .map((region) => ({
                    label: convertRegionType(region as RegionType), // 強制指定 region 類型
                    value: region,
                  }))}
              />
            </Form.Item>
          </RowContent>
          <RowContent width={300}>
            <Form.Item label="地區" name="regionId" rules={[{ required: true, message: "" }]}>
              <StyledSelect placeholder="請選擇" onChange={(e) => handleRegionId(Number(e))}>
                {retailRegionList &&
                  retailRegionList.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
            </Form.Item>
          </RowContent>
          <RowContent width={300}>
            <Form.Item label="通路名稱" name="retailer" rules={[{ required: true, message: "" }]}>
              <StyledSelect placeholder="請選擇" onChange={(e) => handleRetailerId(Number(e))}>
                {retailList &&
                  retailList.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
            </Form.Item>
          </RowContent>
          <RowContent width={300}>
            <Form.Item label="線別" name="productLine" rules={[{ required: true, message: "" }]}>
              <StyledSelect placeholder="請選擇" onChange={(productLineId) => handleContract(Number(productLineId))}>
                {retailerProductLine.map((item: any) => {
                  return (
                    <Select.Option key={item.productLine.id} value={item.productLine.id}>
                      {item.productLine.name}
                    </Select.Option>
                  );
                })}
              </StyledSelect>
            </Form.Item>
          </RowContent>
          <RowContent width={400}>
            <Form.Item label="檢視合約" name="contract">
              {productLineNumber &&
                contractList.map((item) => {
                  return item.contractInfo ? (
                    <ContractText key={item.id} onClick={() => handleOpenContract(item.contractInfo.path)}>
                      {item.contractInfo.name}
                    </ContractText>
                  ) : (
                    <Text>N/A</Text>
                  );
                })}
            </Form.Item>
          </RowContent>
          <RowContent width={400}>
            <Form.Item label="合約開始時間" name="startAt">
              {productLineNumber &&
                contractList.map((item) => {
                  return item.contractInfo ? <Text>{item.contractInfo.startAt}</Text> : <Text>N/A</Text>;
                })}
            </Form.Item>
          </RowContent>
          <RowContent width={400}>
            <Form.Item label="合約結束時間" name="endAt">
              {productLineNumber &&
                contractList.map((item) => {
                  return item.contractInfo ? <Text>{item.contractInfo.endAt}</Text> : <Text>N/A</Text>;
                })}
            </Form.Item>
          </RowContent>
          <Footer>
            {!retailList && <ErrorText>*查無通路，請於[通路/經銷商]內建置</ErrorText>}
            <ButtonContainer>
              <CancelButton onClick={onClose}>取消</CancelButton>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </ButtonContainer>
          </Footer>
        </Form>
      </PopWrapper>
    </PopupBackground>
  );
};

export default RetailerPopup;
