import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import { Select } from "antd";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";

/* Props說明 :

  fetchListApi: 取得list的api
  optionsKey: label(選項文字）和value（回傳值）的key
  onChange: 選擇後的callback
  defaultValue: 帶入預設值
  limit: 一次取得的項目數量

  ------ example ------
   <Form.Item name="formName" label="名稱" rules={[{ required: true, message: "請填入 名稱" }]}>
      <SearchSelect
        fetchListApi={api.fetchList}
        optionsKey={{ label: "name", value: "id" }}  => value為 id => onChange回傳id
        defaultValue={form.getFieldValue("formName")}
        onChange={(value) => {
        form.setFieldsValue({ formName: value }); -> 回傳id
         }}
      />
  </Form.Item>
*/
type Props = {
  fetchListApi: (params: ListParams) => Promise<ListResult>;
  optionsKey: {
    label: string;
    value: string;
  };
  onChange: (value: any) => void;
  defaultValue?: any;
  limit?: number;
  mode?: "multiple" | "tags" | undefined;
  placeholder?: string;
};

export type ListParams = { nameQ?: string; limit: number; offset: number; [key: string]: any };

type ListResult = {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
};

const SearchSelect = (props: Props) => {
  const {
    fetchListApi,
    optionsKey,
    onChange,
    defaultValue,
    limit = 10,
    mode = undefined,
    placeholder = "請選擇",
  } = props;
  const [init, setInit] = useState(false);
  const [initValue, setInitValue] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const [listResult, setListResult] = useState<ListResult>({ count: 0, next: null, previous: null, results: [] });
  const offset = useRef(0);
  const { label, value } = optionsKey;

  const fetchListResults = useCallback(
    async (params: ListParams, loadMore?: boolean) => {
      setIsFetching(true);
      try {
        const response = await fetchListApi(params);
        setListResult((prevState) => {
          const results = loadMore ? [...prevState.results, ...response.results] : response.results;
          return {
            ...response,
            results,
          };
        });
        if (!init) {
          setInit(true);
        }
        return response;
      } catch (error: any) {
        return { count: 0, next: null, previous: null, results: [] };
      } finally {
        setIsFetching(false);
      }
    },
    [fetchListApi, init],
  );

  const handleOnKeywordSearch = useDebounce(async (keyword: string) => {
    fetchListResults({ nameQ: keyword, offset: 0, limit: 300 });
  }, 300);

  const handleOnLoadMore = useCallback(() => {
    const nextOffset = offset.current + limit;
    fetchListResults({ limit, offset: nextOffset }, true);
    offset.current = nextOffset;
  }, [fetchListResults, limit]);

  const handleOnChange = (selectValue: any) => {
    onChange(selectValue);
  };

  const selectorOptions = useMemo(() => {
    const { next, results } = listResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr[value]}>
        {vdr[label]}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnLoadMore} />
        </Select.Option>,
      );
    }
    return options;
  }, [handleOnLoadMore, label, listResult, value]);

  useEffect(() => {
    if (!init) {
      fetchListResults({ limit, offset: 0 });
    }
  }, [fetchListResults, init, limit]);

  useEffect(() => {
    if (init) {
      setInitValue(defaultValue);
    }
  }, [defaultValue, init]);

  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={handleOnKeywordSearch}
      onChange={handleOnChange}
      loading={isFetching}
      placeholder={placeholder}
      value={initValue}
      mode={mode}
    >
      {selectorOptions}
    </Select>
  );
};

export default SearchSelect;
