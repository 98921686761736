import brandOperationApi, { FetchVendorListParam, IntoWarehouseDetails } from "@api/brandOperationApi";
import { Currency, PurchaseStatus } from "@api/utils/normalizeWarehouse";
import {
  EditIWApplication,
  fetchVendorList,
  IWBrandState,
  setEditModal,
} from "@redux/brandOperation/intoWarehouseBrandSlice";
import { DatePicker, Empty, Form, message, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabContentWrapper } from "src/styles/common";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { FormInstance } from "antd/lib/form";
import { useParams } from "react-router-dom";
import moment from "moment";
import InfiniteScrollSelect from "@component/Common/InfiniteScrollSelect";

const ColLabel = styled.p`
  min-width: 130px;
  ${fontStyle("14px", "16px")};
  margin-bottom: 33px;
`;
const Col = styled(ColLabel)``;

type Props = {
  intoWarehouseDetails?: IntoWarehouseDetails;
};
enum EditStatus {
  NOT_UPLOADING, // 未上傳LMS
  UPLOADED, // 上傳LMS 尚未入庫 (有stoNumber，沒有stockinDate)
  STOCKED, // 已入庫 （有stockinDate）
}

const EditTab = (props: Props) => {
  const { intoWarehouseDetails } = props;
  const dispatch = useDispatch();
  const { editModal, isFetching } = useSelector(IWBrandState);
  const { id: applicationId } = useParams();

  const [editForm] = useForm();

  const convertStatus = (status?: PurchaseStatus) => {
    switch (status) {
      case PurchaseStatus.NORMAL:
        return "正常";
      case PurchaseStatus.INVALID:
        return "作廢";
      case PurchaseStatus.DESTROYED:
        return "人工作廢";
      case PurchaseStatus.DEPLOY_SUCCESS:
        return "已拋單";
      case PurchaseStatus.FINISH:
        return "已送達";
      default:
        return "";
    }
  };

  const convertCurrency = (currency?: Currency) => {
    switch (currency) {
      case Currency.NTD:
        return "台幣";
      case Currency.USD:
        return "美金";
      case Currency.EUR:
        return "歐元";
      case Currency.GBP:
        return "英鎊";
      case Currency.AUD:
        return "澳幣";
      case Currency.JPY:
        return "日圓";
      case Currency.KRW:
        return "韓圓";
      default:
        return "";
    }
  };

  const editStatus = useMemo(() => {
    if (!intoWarehouseDetails) return EditStatus.NOT_UPLOADING;
    const { stoNumber, stockinDate } = intoWarehouseDetails;
    if (stoNumber && !stockinDate) return EditStatus.UPLOADED;
    if (stockinDate) return EditStatus.STOCKED;
    return EditStatus.NOT_UPLOADING;
  }, [intoWarehouseDetails]);
  const onEditSubmit = () => {
    const { supplyChain, specDate } = editForm.getFieldsValue();
    dispatch(
      EditIWApplication({
        applicationId: Number(applicationId),
        supplyChain: Number(supplyChain),
        specDate: specDate.format("YYYY-MM-DD"),
      }),
    );
  };
  if (isFetching) return null;
  if (!intoWarehouseDetails) return <Empty />;
  return (
    <TabContentWrapper>
      <Row>
        <ColLabel>LMS編號</ColLabel>
        <Col>{intoWarehouseDetails.stoNumber || "N/A"}</Col>
      </Row>
      <Row>
        <ColLabel>開單人員</ColLabel>
        <Col>{intoWarehouseDetails.staff?.name || "N/A"}</Col>
      </Row>
      <Row>
        <ColLabel>狀態</ColLabel>
        <Col>{convertStatus(intoWarehouseDetails.status)}</Col>
      </Row>
      <Row>
        <ColLabel>供應商名稱</ColLabel>
        <Col>{intoWarehouseDetails.supplyChain?.name}</Col>
      </Row>
      <Row>
        <ColLabel>採購商品幣別</ColLabel>
        <Col>{convertCurrency(intoWarehouseDetails.currency)}</Col>
      </Row>
      <Row>
        <ColLabel>預計進倉日期</ColLabel>
        <Col>{intoWarehouseDetails.specDate}</Col>
      </Row>
      <Row>
        <ColLabel>實際進倉日期</ColLabel>
        <Col>{intoWarehouseDetails.stockinDate || "N/A"}</Col>
      </Row>
      <Row>
        <ColLabel>申請日期</ColLabel>
        <Col>{intoWarehouseDetails.applicationDate || "N/A"}</Col>
      </Row>
      <Row>
        <ColLabel>給倉庫的備註</ColLabel>
        <Col>{intoWarehouseDetails.note || "N/A"}</Col>
      </Row>
      <EditModal
        open={editModal}
        onSubmit={onEditSubmit}
        onClose={() => dispatch(setEditModal(false))}
        form={editForm}
        supplyChain={intoWarehouseDetails.supplyChain?.name}
        specDate={intoWarehouseDetails.specDate}
        editStatus={editStatus}
      />
    </TabContentWrapper>
  );
};
export default EditTab;

type EditProps = {
  onSubmit: () => void;
  onClose: () => void;
  open: boolean;
  form: FormInstance;
  supplyChain: string;
  specDate: string;
  editStatus: EditStatus;
};

const EditModal = (props: EditProps) => {
  const { onSubmit, onClose, open, form, supplyChain, specDate, editStatus } = props;
  const dispatch = useDispatch();
  const { isFetching } = useSelector(IWBrandState);

  const handleOnSubmit = () => {
    onSubmit();
  };
  const handleCancel = () => {
    onClose();
  };

  const fetchSupplyChainList = async (searchParams: FetchVendorListParam) => {
    const response = await brandOperationApi.fetchVendorList(searchParams);
    return response;
  };

  useEffect(() => {
    dispatch(fetchVendorList(""));
    form.setFieldsValue({ supplyChain, specDate: moment(specDate) });
  }, [dispatch, form, supplyChain, specDate]);

  return (
    <Modal
      visible={open}
      confirmLoading={isFetching}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      okText="確認"
      cancelText="取消"
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        onFinish={handleOnSubmit}
        onFinishFailed={() => message.error("必填欄位未填寫")}
      >
        <Form.Item label="供應商名稱" name="supplyChain" rules={[{ required: true, message: "" }]}>
          <InfiniteScrollSelect
            fetchOptions={fetchSupplyChainList}
            showSearch
            allowClear
            disabled={editStatus !== EditStatus.NOT_UPLOADING}
          />
        </Form.Item>
        <Form.Item label="預計進倉日" name="specDate" rules={[{ required: true, message: "" }]}>
          <DatePicker style={{ width: "80%" }} disabled={editStatus === EditStatus.STOCKED} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
