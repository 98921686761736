import downloadCSV from "@utils/downloadCSV";
import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import manufacturerApi, { ManufacturerApi } from "./brandOperation/manufacturerApi";

export enum ModifyStatus {
  ALL = 0,
  LIMIT = 1, // 限制修改(purchase_price only)
  NONE = 2,
}
const apiClient = new CoreAPI();

export interface PruductFilter {
  skus?: string;
  name?: string;
  no?: string;
  limit: number;
  offset: number;
}
export interface SupplyChainPayload {
  currency: number;
  isActive: number;
  quotedPrice: number;
  supplyChainId: number;
}

export interface ProductListResult {
  id: number;
  no: string;
  sku: string;
  name: string;
  cost: number;
  stockSum: number;
}
export interface ProductSupplyChainListResult {
  supplyChainName: string;
  quotedPrice: number;
  currency: string;
  isActive: boolean;
  supplyChainId: number;
  id?: number;
}
export interface ProductList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ProductListResult[];
}
export interface ProductSupplyChainList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ProductSupplyChainListResult[];
}
export interface SupplyChainInfo {
  id: number;
  supplyChainName: string;
  supplyChainId: number;
  quotedPrice: number | undefined;
  currency: number | undefined;
  isActive: number;
}
export interface ProductInfo {
  id: number;
  no?: string;
  sku: string;
  name: string;
  nameEn: string;
  cost: number;
  type: string;
  shelfLife: number;
  pcsPerCarton: number;
  weight: number;
  length: number;
  width: number;
  height: number;
  cartonWeight: number;
  cartonLength: number;
  cartonWidth: number;
  cartonHeight: number;
  totalNetWeight?: number;
  totalGrossWeight?: number;
  totalVolume?: number;
}
export interface SupplyChainList {
  count: number;
  next: string | null;
  previous: string | null;
  results: SupplyChainDetail[];
}
export interface SupplyChainDetail {
  id: number;
  name: string;
  address: string;
  phone: string;
  ubn: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  currency: number | string;
  staff: string;
}

// 庫存效期
export interface BOStockList {
  count: number;
  next: null | string;
  previous: null | string;
  totalAmount: number;
  totalStock: number;
  results: BOStockListItem[];
}
export interface BOStockListParams {
  no?: string;
  skus?: string;
  storageType?: string;
  nameQ?: string;
  limit: number;
  offset: number;
}
export interface BOStockListItem {
  id: number;
  no: string;
  sku: string;
  productName: string;
  storageType: string;
  canSakesQty: number;
  stockValue: number;
  details: [BOStockItemDetail];
}

export interface BOStockItemDetail {
  id: number;
  sku: string;
  productName: string;
  storageType: string;
  effectiveDate: string;
  batch: string;
  canSakesQty: number;
  stockValue: number;
}

// 進倉
export interface IntoWarehouseList {
  count: number;
  next: string;
  previous: string;
  results: IntoWarehouseItem[];
}
export interface IntoWarehouseItem {
  id: number;
  stoNumber: number;
  supplyChain: {
    id: number;
    name: string;
  };
  totalRequestQty: number;
  totalArrivedQty: number;
  specDate: string;
  stockinDate: string;
  tariffNumber: string;
  // TODO
  PurchaseOrderNo1: string;
  destroyedAt: string;
}

export interface IntoWarehouseParams {
  stoNumber?: number;
  supplyChain?: string;
  skus?: string;
  specDateAfter?: string;
  specDateBefore?: string;
  limit: number;
  offset: number;
}
export interface AddIWApplicationParams {
  supplyChain: number;
  specDate: string;
}
export interface IntoWarehouseDetails {
  id: number;
  stoNumber: number;
  staff: {
    id: number;
    name: string;
  };
  supplyChain: {
    id: number;
    name: string;
  };
  status: number;
  currency: number;
  specDate: string;
  stockinDate: string;
  applicationDate: string;
  matoLink: string;
  note: string;
}
export interface IWDetailsProductItem {
  id: number;
  no?: string;
  sku: string;
  storageType: string;
  batch: number;
  purchasePrice: number;
  requestQty: number;
  expiredDate: string;
  applicationId?: number;
}
export interface FetchVendorListParam {
  limit: number;
  offset: number;
  nameQ?: string;
}

export interface IWAppProductList {
  count: number;
  next: string;
  previous: string;
  results: IWAppProductItem[];
}
export interface IWAppProductItem {
  id: number;
  no: string;
  sku: string;
  name: string;
  storageType: string;
  batch: string;
  purchasePrice: number;
  amount4: number;
  expiredDate: string;
  modifyStatus: ModifyStatus;
  stockinDate: string;
  arrivedQty: number;
}

export interface UploadErrorReason {
  httpStatus: number;
  message: string;
  result: string;
  statusCode: number;
}
export interface ProductManufacturerResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ProductManufacturerDetail[];
}
export interface ProductManufacturerDetail {
  id?: number;
  producerName?: string;
  producerId: number;
  quotedPrice: number;
  leadTime: string;
  pcsPerCarton: number;
}
interface BrandOperationApi extends ManufacturerApi {
  // 商品
  fetchProductList: (param: PruductFilter) => Promise<ProductList>;
  fetchProductInfo: (pruductId: string) => Promise<ProductInfo>;
  fetchAddProduct: (param: ProductInfo) => Promise<ProductInfo>;
  fetchUpdateProduct: (param: { data: ProductInfo; productId: number }) => Promise<ProductInfo>;
  fetchProductSupplyChainList: (pruductId: string) => Promise<ProductSupplyChainList>;
  fetchProductSupplyChainInfo: (pruductId: string, supplyChainId: number) => Promise<SupplyChainInfo>;
  fetchUpdateSupplyChainInfo: (pruductId: string, supplyChainInfoId: number, value: any) => Promise<SupplyChainInfo>;
  fetchDeleteSupplyChainInfo: (pruductId: string, supplyChainId: string) => Promise<void>;
  fetchAddSupplyChainInfo: (pruductId: string, value: SupplyChainPayload) => Promise<void>;
  fetchSupplyChain: (params: any) => Promise<any>;
  // 商品x製造商
  fetchProductManufacturerList: (productId: string) => Promise<ProductManufacturerResult>;
  updateProductManufacturer: (
    pruductId: string,
    manufacturerId: number,
    payload: ProductManufacturerDetail,
  ) => Promise<void>;
  addProductManufacturer: (pruductId: string, payload: ProductManufacturerDetail) => Promise<any>;
  deleteProductManufacturer: (pruductId: string, manufacturerId: number) => Promise<void>;
  // 庫存效期
  fetchBOStockList: (params: BOStockListParams) => Promise<BOStockList>;
  exportBOStockListCSV: (filter: { skus?: string; storageType?: string; nameQ?: string }) => Promise<void>;
  // 進倉
  fetchIntoWarehouseList: (params: IntoWarehouseParams) => Promise<IntoWarehouseList>;
  fetchVendorList: (params: FetchVendorListParam) => Promise<SupplyChainList>;
  addIWApplication: (params: AddIWApplicationParams) => Promise<void>;
  copyIWApplication: (applicationId: number) => Promise<void>;
  invalidIWApplication: (applicationId: number) => Promise<void>;
  editIWApplication: (applicationId: number, params: { supplyChain: number; specDate: string }) => Promise<void>;
  fetchIWApplicationDetails: (applicationId: number) => Promise<IntoWarehouseDetails>;
  fetchIWAppProductList: (applicationId: number, limit: number, offset: number) => Promise<IWAppProductList>;
  addIWapplicationProduct: (applicationId: number, params: IWDetailsProductItem) => Promise<void>;
  editIWapplicationProduct: (applicationId: number, params: IWDetailsProductItem) => Promise<void>;
  fetchBrandVpc: (applicationId: number, no: string) => Promise<{ name: string; no: string }>;
  importIWappsProductsXLS: (applicationId: number, file: File) => Promise<void>;
  deleteIWappsProducts: (applicationId: number, productId: number) => Promise<void>;
  uploadWMSystem: (applicationId: number) => Promise<void>;
}

const brandOperationApi: BrandOperationApi = {
  ...manufacturerApi,
  fetchProductList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/tryall/manage/product/products/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductInfo: async (pruductId) => {
    const response = await apiClient.getData(`/tryall/manage/product/products/${pruductId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchAddProduct: async (param) => {
    const response = await apiClient.postData("/tryall/manage/product/products/", transformCamelToSnake(param));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateProduct: async (param) => {
    const response = await apiClient.putData(
      `/tryall/manage/product/products/${param.productId}/`,
      transformCamelToSnake(param.data),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductSupplyChainList: async (productId) => {
    const response = await apiClient.getData(`/tryall/manage/product/products/${productId}/supply-chains/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductSupplyChainInfo: async (productId, supplyChainId) => {
    const response = await apiClient.getData(
      `/tryall/manage/product/products/${productId}/supply-chains/${supplyChainId}`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddSupplyChainInfo: async (productId, value) => {
    await apiClient.postData(
      `/tryall/manage/product/products/${productId}/supply-chains/`,
      transformCamelToSnake(value),
    );
  },
  fetchUpdateSupplyChainInfo: async (productId, supplyChainInfoId, value) => {
    const response = await apiClient.putData(
      `/tryall/manage/product/products/${productId}/supply-chains/${supplyChainInfoId}/`,
      transformCamelToSnake(value),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchDeleteSupplyChainInfo: async (productId, supplyChainId) => {
    await apiClient.deleteData(`/tryall/manage/product/products/${productId}/supply-chains/${supplyChainId}`, {});
  },
  fetchSupplyChain: async (params) => {
    const response = await apiClient.getData("/manage/retail/supply-chain/", params);
    return transformAPIKeyToCamel(response.data.result);
  },
  // 商品x製造商
  fetchProductManufacturerList: async (pruductId) => {
    const response = await apiClient.getData(`/tryall/manage/product/products/${pruductId}/producer-relation/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  addProductManufacturer: async (productId, payload) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData(
      `/tryall/manage/product/products/${productId}/producer-relation/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  updateProductManufacturer: async (productId, manufacturerId, payload) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(
      `/tryall/manage/product/products/${productId}/producer-relation/${manufacturerId}/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteProductManufacturer: async (productId: string, manufacturerId: number) => {
    await apiClient.deleteData(`/tryall/manage/product/products/${productId}/producer-relation/${manufacturerId}/`, {});
  },

  // 庫存效期
  fetchBOStockList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/stock/stocks/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },

  exportBOStockListCSV: async (filter) => {
    const getParams = transformCamelToSnake(filter);
    const response = await apiClient.getData("/tryall/manage/stock/stocks/csv-download", getParams);
    downloadCSV(response);
  },

  // 進倉
  fetchIntoWarehouseList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/tryall/manage/purchase/purchases/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVendorList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/retail/supply-chain/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  addIWApplication: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/tryall/manage/purchase/purchases/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  copyIWApplication: async (applicationId) => {
    const response = await apiClient.postData(`/tryall/manage/purchase/purchases/${applicationId}/copy/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  invalidIWApplication: async (applicationId) => {
    const response = await apiClient.putData(`/tryall/manage/purchase/purchases/${applicationId}/destroy/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  editIWApplication: async (applicationId, param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.putData(`/tryall/manage/purchase/purchases/${applicationId}/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchIWApplicationDetails: async (applicationId) => {
    const response = await apiClient.getData(`/tryall/manage/purchase/purchases/${applicationId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  addIWapplicationProduct: async (applicationId: number, params: IWDetailsProductItem) => {
    const payload = {
      ...params,
      no: params.no?.trim(),
    };
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData(
      `/tryall/manage/purchase/purchases/${applicationId}/purchase-lines/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  editIWapplicationProduct: async (applicationId: number, params: IWDetailsProductItem) => {
    const { id, ...others } = params;
    const payload = {
      ...others,
      no: others.no?.trim(),
    };
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(
      `/tryall/manage/purchase/purchases/${applicationId}/purchase-lines/${id}/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchIWAppProductList: async (applicationId: number, limit: number, offset: number) => {
    const response = await apiClient.getData(
      `/tryall/manage/purchase/purchases/${applicationId}/purchase-lines/?limit=${limit}&offset=${offset}`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchBrandVpc: async (applicationId: number, no: string) => {
    const response = await apiClient.getData(
      `/tryall/manage/purchase/purchases/${applicationId}/purchase-lines/check/?no=${no}`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  importIWappsProductsXLS: async (applicationId: number, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.postData(
      `/tryall/manage/purchase/purchases/${applicationId}/purchase-lines/upload/`,
      formData,
    );
    return response.data;
  },
  deleteIWappsProducts: async (applicationId: number, productId: number) => {
    const response = await apiClient.deleteData(
      `/tryall/manage/purchase/purchases/${applicationId}/purchase-lines/${productId}/`,
      {},
    );
    return response.data;
  },
  uploadWMSystem: async (applicationId: number) => {
    const response = await apiClient.putData(`/tryall/manage/purchase/purchases/${applicationId}/deploy-to-lms/`, {});
    return response.data;
  },
};

export default brandOperationApi;
