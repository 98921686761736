import { InfoCircleTwoTone } from "@ant-design/icons";
import { ReturnProductAppItem, UpdateAppDetailPayload } from "@api/sharing/ReturnProductManagementApi";
import { RetailList } from "@api/throughShipment/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import PopupBackground from "@component/PopupBackground";
import { InvoiceTypeOptions } from "@constant/InvoiceType";
import { warehouseCodeOptions } from "@constant/WarehouseCodeValue";
import {
  applyReturnProductApp,
  fetchReturnPlatformList,
  fetchReturnProductAppDetail,
  returnProductState,
  updateReturnProductApp,
} from "@redux/sharing/ReturnProductManagementSlice";

import { Button, Col, Form, Input, Row, Select, Skeleton, Space, Spin, Tabs, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StyledSection } from "src/styles/common";
import styled from "styled-components";
import ReturnProductList from "./ReturnProductList";

const StyledWrapper = styled(StyledSection)`
  position: relative;
  border: 1px solid #f0f0f0;
  margin: 16px 25px;
  padding: 8px 11px;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 20px;
  z-index: 2;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  padding: 32px;
  background-color: white;
  border-radius: 4px;
`;
const TooltipWrapper = styled.div`
  position: relative;
  width: 98px;
`;

export default function ReturnWarehouseDetailsPage() {
  const dispatch = useDispatch();
  const { returnId: returnIdStr } = useParams();
  const returnId = Number(returnIdStr);
  const {
    isFetching,
    returnProductAppDetail: details,
    retailList,
    returnProductList: { count: productCount },
  } = useSelector(returnProductState);

  const editDisabled = !!details?.submittedAt || !!details?.destroyedAt;
  const canSubmit = details?.canSubmit && productCount > 0;

  const [activeTab, setActiveTab] = useState("details");
  const [openEditModal, setOpenEditModal] = useState<Boolean>(false);

  const handleUpdate = (payload: UpdateAppDetailPayload) => {
    dispatch(updateReturnProductApp({ returnId, payload }));
  };
  const handelSubmit = () => {
    dispatch(applyReturnProductApp(returnId));
  };

  useEffect(() => {
    if (returnId) {
      dispatch(fetchReturnProductAppDetail(returnId));
      dispatch(fetchReturnPlatformList({ contractTypes: [10, 30] }));
    }
  }, [dispatch, returnId]);

  return (
    <StyledWrapper>
      <PageTitle title="退貨管理" />
      <ButtonWrapper>
        {activeTab === "details" && (
          <Space>
            <Button type="primary" onClick={() => setOpenEditModal(true)} disabled={editDisabled}>
              編輯
            </Button>
          </Space>
        )}
        {activeTab === "products" && (
          <Space>
            <Button type="primary" onClick={() => handelSubmit()} disabled={!canSubmit}>
              提交
            </Button>
          </Space>
        )}
      </ButtonWrapper>
      {details ? (
        <>
          <Tabs
            onChange={(activeKey: string) => setActiveTab(activeKey)}
            type="card"
            items={[
              {
                label: "編輯退貨單",
                key: "details",
                children: <ReturnProductAppDetails itemDetails={details} isFetching={isFetching} />,
              },
              { label: "退貨商品", key: "products", children: <ReturnProductList returnId={returnId} /> },
            ]}
          />
          {openEditModal && (
            <EditModal
              appDetail={details}
              onClose={() => {
                setOpenEditModal(false);
              }}
              onSubmit={handleUpdate}
              retailList={retailList}
            />
          )}
        </>
      ) : (
        <Skeleton />
      )}
    </StyledWrapper>
  );
}

const EditModal = (props: {
  appDetail: ReturnProductAppItem;
  onClose: () => void;
  onSubmit: (payload: UpdateAppDetailPayload) => void;
  retailList?: RetailList[];
}) => {
  const { appDetail, onClose, onSubmit, retailList } = props;
  const [form] = useForm();
  const handelOnSubmit = (values: any) => {
    onSubmit(values);
    onClose();
  };

  const platformOptions = retailList?.map((item) => ({ label: item.name, value: item.name })) || [];

  return (
    <PopupBackground close={() => {}}>
      <Wrapper>
        <Form
          form={form}
          labelCol={{ span: 7 }}
          labelAlign="left"
          colon={false}
          initialValues={appDetail}
          onFinish={handelOnSubmit}
        >
          <Form.Item label="通路平台" name="platform" rules={[{ required: true, message: "" }]}>
            <Select options={platformOptions} placeholder="請選擇" allowClear showSearch />
          </Form.Item>
          <TooltipWrapper>
            <Tooltip placement="bottom" title="如果商品會退回到黑貓倉或是藍田倉，則此欄位需填寫「是」，反之則為「否」">
              <InfoCircleTwoTone style={{ position: "absolute", zIndex: 1, top: 10, right: 11 }} />
            </Tooltip>
          </TooltipWrapper>
          <Form.Item label="是否入庫" name="isReturnToWarehouse" rules={[{ required: true, message: "" }]}>
            <Select
              options={[
                { label: "是", value: true },
                { label: "否", value: false },
              ]}
              placeholder="請選擇"
              allowClear
            />
          </Form.Item>
          <Form.Item label="退貨單號" name="returnNumber" rules={[{ required: true, message: "" }]}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label="退件人" name="receiverName" rules={[{ required: true, message: "" }]}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label="原始訂單編號" name="originalPlatformNumber">
            <Input allowClear />
          </Form.Item>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={onClose}>取消</Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
};

type ItemKeys = keyof ReturnProductAppItem;

type RenderFunction = {
  label: string | React.ReactNode;
  dataKey: ItemKeys | "";
  render?: (value: any, record: ReturnProductAppItem) => React.ReactNode;
};

const ReturnProductAppDetails = (props: { itemDetails: ReturnProductAppItem; isFetching: boolean }) => {
  const { itemDetails, isFetching } = props;
  const fieldsData: RenderFunction[] = useMemo(
    () => [
      { label: "編號", dataKey: "id" },
      { label: "建立人員", dataKey: "staff" },
      { label: "建立時間", dataKey: "createdDate" },
      { label: "通路/平台", dataKey: "platform" },
      {
        label: (
          <Space>
            <span>是否入庫</span>
            <Tooltip placement="bottom" title="如果商品會退回到黑貓倉或是藍田倉，則此欄位需填寫「是」，反之則為「否」">
              <InfoCircleTwoTone />
            </Tooltip>
          </Space>
        ),
        dataKey: "isReturnToWarehouse",
        render: (value) => (value ? "是" : "否"),
      },
      {
        label: "入庫倉庫",
        dataKey: "warehouseCode",
        render: (value) => warehouseCodeOptions.find((item) => item.value === value)?.label || "-",
      },
      { label: "退貨單號", dataKey: "returnNumber" },
      { label: "退件人", dataKey: "receiverName" },
      { label: "退貨單備註", dataKey: "note" },
      { label: "原始訂單編號", dataKey: "originalPlatformNumber" },
      { label: "退件人電話", dataKey: "receiverPhone" },
      { label: "郵遞區號", dataKey: "receiverZipcode" },
      { label: "退件人地址", dataKey: "receiverAddress" },
      { label: "逆物流編號", dataKey: "shipperNumber" },
      { label: "實際入庫時間", dataKey: "stockinDate" },
      { label: "發票號碼", dataKey: "invoiceNumber" },
      { label: "發票金額", dataKey: "invoiceAmount" },
      {
        label: "發票作業",
        dataKey: "invoiceType",
        render: (value) => InvoiceTypeOptions.find((option) => value === option.value)?.label,
      },
      { label: "發票建立人員", dataKey: "invoicedBy" },
    ],
    [],
  );

  return (
    <Spin spinning={isFetching}>
      <StyledSection>{fieldsData.map((field) => GenerateRow(field, itemDetails))}</StyledSection>
    </Spin>
  );
};

const GenerateRow = (field: RenderFunction, details: ReturnProductAppItem) => {
  const { dataKey, render, label } = field;
  const data = dataKey !== "" ? details[dataKey] : "N/A";
  return (
    <Row gutter={24} style={{ marginBottom: "24px" }} key={field.dataKey}>
      <Col span={6}>{label}</Col>
      <Col flex="auto">{render ? render(data, details) : data ?? "-"}</Col>
    </Row>
  );
};
