/* eslint-disable camelcase */
import {
  Filters,
  OrderParams,
  ReturnErrorResult,
  ReturnOrderItem,
  ReturnOrderProcessType,
  ReturnProductResult,
  CreateProduct,
  ReturnOrderListResult,
  ReturnOrderDetail,
  UpdateProduct,
  CopyWithProductsParams,
  CarrierList,
  WarehouseList,
} from "@page/ReturnOrder/interfaces";
import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const request = new CoreAPI();

export interface ProductStockInfoList {
  stockInfo: ProductStockInfo[];
}

export interface ProductStockInfo {
  batch: string | null;
  canSalesQty: number;
  effectiveDate: string | null;
  storageType: string;
  warehouseCode: string;
}

interface ProductBarcodeInfoDetail {
  sku: string;
  warehouseCode: string;
  productName: string;
  barcode: string;
  weightedCost: number;
}
export interface ProductBarcodeInfo {
  count: number;
  next: null;
  previous: null;
  results: ProductBarcodeInfoDetail[];
}

interface ReturnOrderApi {
  // 主單
  fetchCarrierList: (sourceWarehouseCode: string) => Promise<CarrierList[]>;
  fetchWarehouseList: (isExternal: number) => Promise<WarehouseList[]>;
  fetchReturnOrderList: (filters: Filters) => Promise<ReturnOrderListResult>;
  fetchReturnOrderDetail: (orderId: string | number) => Promise<ReturnOrderDetail>;

  updateReturnOrder: (params: OrderParams) => Promise<ReturnOrderDetail>;
  createReturnOrder: (params: OrderParams) => Promise<ReturnOrderItem>;
  applyOrApproveReturnOrder: (
    orderId: string | number,
    processType: ReturnOrderProcessType,
  ) => Promise<StandardResponse>;
  approveReturnOrder: (orderId: number, isApproved: boolean) => Promise<StandardResponse>;
  deleteReturnOrder: (orderId: number) => Promise<StandardResponse>;

  // 商品
  fetchReturnOrderProductList: (
    orderId: string,
    filter: {
      limit: number;
      offset: number;
    },
  ) => Promise<ReturnProductResult>;
  addOrderProduct: (params: CreateProduct) => Promise<void>;
  copyReturnOrderWithProducts: (
    params: CopyWithProductsParams,
  ) => Promise<{ resultError: ReturnErrorResult[]; id: number }>;
  deleteOrderProduct: (payload: { returnOrderId: string | number; productId: number }) => Promise<void>;
  updateReturnProduct: (params: UpdateProduct) => Promise<void>;
  importReturnProductXLS: (returnOrderId: string, file: File) => Promise<void>;

  // 編輯出貨商品
  fetchProductBarcodeBySku: (payload: { sku: string; warehouse: string }) => Promise<ProductBarcodeInfo>;
  fetchProductStockBySku: (payload: { sku: string; warehouse: string }) => Promise<ProductStockInfoList[]>;
}

const returnOrderApi: ReturnOrderApi = {
  // 主單
  fetchCarrierList: async (sourceWarehouseCode) => {
    const payload = {
      warehouseCode: sourceWarehouseCode,
      isTransfer: 1,
    };
    const response = await request.getData("/manage/logistic/carrier-relation/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result.results);
  },
  fetchWarehouseList: async (isExternal: number) => {
    const payload = {
      isActive: true,
      isExt: isExternal,
    };
    const response = await request.getData("/manage/stock/warehouses/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result.results);
  },
  fetchReturnOrderList: async (payload) => {
    const response = await request.getData("/manage/lading/ladings/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchReturnOrderDetail: async (id) => {
    const response = await request.getData(`/manage/lading/ladings/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  createReturnOrder: async (payload) => {
    const response = await request.postData("/manage/lading/ladings/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  updateReturnOrder: async (payload) => {
    const { returnOrderId, ...restPayload } = payload;
    const response = await request.putData(
      `/manage/lading/ladings/${returnOrderId}/`,
      transformCamelToSnake(restPayload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  copyReturnOrderWithProducts: async (payload) => {
    const { returnOrderId, ...restPayload } = payload;
    const response = await request.postData(
      `/manage/lading/ladings/${returnOrderId}/duplicate/`,
      transformCamelToSnake(restPayload),
    );
    return { resultError: transformAPIKeyToCamel(response.data).errorResult, id: response.data.result.id };
  },
  deleteReturnOrder: async (orderId) => {
    const response = await request.deleteData(`/manage/lading/ladings/${orderId}/`, {});
    return response.data;
  },
  applyOrApproveReturnOrder: async (orderId, processType) => {
    const response = await request.putData(`/manage/lading/ladings/${orderId}/process/`, {
      process_type: processType,
    });
    return response.data;
  },
  importReturnProductXLS: async (returnOrderId: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await request.postData(`/manage/lading/ladings/${returnOrderId}/lading-lines/upload/`, formData);
    return transformAPIKeyToCamel(response.data);
  },
  approveReturnOrder: async (orderId, isApproved) => {
    const response = await request.putData(`/manage/lading/ladings/${orderId}/approve/`, {
      is_approved: isApproved,
    });
    return response.data;
  },

  // 商品
  fetchReturnOrderProductList: async (orderId, filter) => {
    const response = await request.getData(
      `/manage/lading/ladings/${orderId}/lading-lines/`,
      transformCamelToSnake(filter),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  addOrderProduct: async (payload) => {
    const { returnOrderId, ...restPayload } = payload;
    await request.postData(`/manage/lading/ladings/${returnOrderId}/lading-lines/`, transformCamelToSnake(restPayload));
  },
  deleteOrderProduct: async (payload) => {
    const { returnOrderId, productId } = payload;
    await request.deleteData(`/manage/lading/ladings/${returnOrderId}/lading-lines/${productId}/`, {});
  },
  updateReturnProduct: async (payload) => {
    const { returnOrderId, productId, ...restPayload } = payload;
    const response = await request.putData(
      `/manage/lading/ladings/${returnOrderId}/lading-lines/${productId}/`,
      transformCamelToSnake(restPayload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductBarcodeBySku: async (payload) => {
    const params = {
      warehouseCode: payload.warehouse,
      sku: payload.sku,
    };
    const response = await request.getData("/manage/product/product-warehouse/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductStockBySku: async (payload) => {
    const params = {
      warehouseCode: payload.warehouse,
      sku: payload.sku,
      isTransfer: 1,
    };
    const response = await request.getData("/manage/product/product-warehouse/stocks/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
};

export function cancelRequest() {
  request.cancelRequest();
}

export default returnOrderApi;
