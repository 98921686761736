import React, { useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FinanceOrderList, contractTypeMap, OrderListFilter } from "@api/financeOrderApi";
import {
  fetchFinanceOrderList,
  setOrderListFilter,
  financeOrderState,
  deleteFinanceOrder,
} from "@redux/financeOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import IntegratedTable from "@component/Table/IntegratedTable";

const FinanceOrderLists = () => {
  const dispatch = useDispatch();
  const { orderListFilter, financeOrderListResult, isFetching } = useSelector(financeOrderState);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "類型",
      dataIndex: "contractType",
      render: (contractType: number) => contractTypeMap[contractType],
    },
    {
      title: "供應/經銷商",
      dataIndex: "retailerName",
    },
    {
      title: "發票號碼",
      dataIndex: "invoiceNumber",
    },
    {
      title: "發票日期",
      dataIndex: "accountDate",
    },
    {
      title: "訂單總金(含稅)",
      dataIndex: "totalAmount",
    },
    {
      title: "建立人員",
      dataIndex: "createdBy",
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
    },
    {
      title: "提交時間",
      dataIndex: "submittedAt",
    },
    {
      title: "",
      dataIndex: "delete",
      fixed: "right",
      render: (_, record: FinanceOrderList) => {
        return (
          <>
            <Button type="link" onClick={() => window.open(`${window.location.href}/${record.id}`)}>
              檢視
            </Button>
            <Button
              type="link"
              onClick={() => handleDelete(record.id)}
              disabled={!!record.destroyedAt || !!record.submittedAt}
            >
              作廢
            </Button>
          </>
        );
      },
    },
  ];

  const handleDelete = (orderId: number) => {
    Modal.confirm({
      title: "你確定要作廢嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteFinanceOrder(orderId));
      },
    });
  };

  useEffect(() => {
    dispatch(fetchFinanceOrderList(orderListFilter));
  }, [dispatch, orderListFilter]);

  const checkDisabledRow = (record: any) => ({ className: record.destroyedAt && "ur-disabled-text" });

  return (
    <IntegratedTable
      isPagination
      paginationConfig={{
        totalCount: financeOrderListResult.count,
        updateFilterParams: (filter: OrderListFilter) => dispatch(setOrderListFilter(filter)),
        filterParams: orderListFilter,
      }}
      loading={isFetching}
      scroll={{ x: "max-content" }}
      columns={columns}
      dataSource={financeOrderListResult.results}
      onRow={checkDisabledRow}
    />
  );
};

export default FinanceOrderLists;
