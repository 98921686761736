import React, { useState, useEffect, ReactText } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import PageTitle from "@component/PageTitle";
import { Button, Input, Space, Form, Modal, FormInstance, Row, Col } from "antd";
import { PlusCircleTwoTone, ExclamationCircleOutlined, FilterTwoTone } from "@ant-design/icons";
import SupplyChainProductPopup from "@page/SupplyChain/SupplyChainProductPopup";
import {
  fetchRetailerProductList,
  deleteRetailerProduct,
  updateSupplyChainProductSelectedIds,
  batchExportSupplyChainProduct,
  updateRetailerProductFilter,
  resetRetailerProductFilterWithRelation,
  initialState,
} from "@redux/supplyChainSlice";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import {
  RetailerProductDetail,
  DeleteRetailerProductParam,
  SupplyChainProductParams,
  Currency,
  RetailerProductListParam,
} from "@api/supplyChainApi";
import { FilterWrapper, TableWrapper } from "src/styles/common";
import IntegratedTable from "@component/Table/IntegratedTable";
import ImportCSV from "./ImportXLS";

const Wrapper = styled.div`
  margin: 20px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;

const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;

const Text = styled.div`
  text-align: left;
`;

const SupplyChainProductList = () => {
  const [filterForm] = Form.useForm();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<RetailerProductDetail | null>(null);
  const [showImportCSV, setShowImportCSV] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { supplyChainRelationId, currencyNumber } = useParams();
  const {
    isFetching,
    selectedSupplyChainProductSelectedIds,
    retailerProductFilter,
    retailerProductList: list,
  } = useSelector((state: RootState) => state.supplyChain);
  const { results: retailerProductList, count } = list;

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSupplyChainProductSelectedIds(values as number[]));
  };

  let currencyType;
  if (Number(currencyNumber) === Currency.TWD) {
    currencyType = "TWD";
  } else if (Number(currencyNumber) === Currency.USD) {
    currencyType = "USD";
  } else {
    currencyType = "RMB";
  }

  const tableColumns: any = [
    {
      title: "編號",
      dataIndex: "id",
    },
    {
      title: "商品品號",
      dataIndex: "no",
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "品名",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "商品售價(TWD)",
      key: "price",
      dataIndex: "price",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
      sorter: true,
    },
    {
      title: `商品報價(${currencyType})`,
      key: "quotedPrice",
      dataIndex: "quotedPrice",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
      sorter: true,
    },

    {
      title: "箱入數",
      key: "pcsPerCarton",
      dataIndex: "pcsPerCarton",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
    },
    {
      title: "允收天數",
      key: "canAcceptDays",
      dataIndex: "canAcceptDays",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: any) => {
        return <Edit onClick={() => toggleEditPopup(data)}>編輯</Edit>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data: RetailerProductDetail) => {
        const params: DeleteRetailerProductParam = {
          id: data.id,
          supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
        };
        return <Edit onClick={() => handleDelete(params)}>刪除</Edit>;
      },
    },
  ];

  const handleDelete = (params: DeleteRetailerProductParam) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(deleteRetailerProduct(params));
      },
    });
  };
  const toggleEditPopup = (data: any) => {
    setOpenPopup((prev) => !prev);
    setCurrentData(data);
  };

  const handleOnFilterSubmit = (params: RetailerProductListParam) => {
    const filterParams = { ...retailerProductFilter, ...params, offset: 0 };
    dispatch(updateRetailerProductFilter(filterParams));
  };

  const handleReset = () => {
    filterForm.resetFields();
    dispatch(resetRetailerProductFilterWithRelation());
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    if (extra.action === "sort") {
      const { field, order } = sorter;
      let ordering = "";
      switch (order) {
        case "descend":
          ordering = field;
          break;
        case "ascend":
          ordering = `-${field}`;
          break;
        default:
          ordering = "";
      }
      const params = {
        ...retailerProductFilter,
        ordering,
        offset: 0,
      };
      dispatch(updateRetailerProductFilter(params));
    }
  };

  const togglePopup = () => {
    setOpenPopup((prev) => !prev);
    setCurrentData(null);
  };
  const toggleImportCSV = () => {
    setShowImportCSV((prev) => !prev);
  };

  const handleExport = () => {
    const filter: SupplyChainProductParams = {
      productIds: selectedSupplyChainProductSelectedIds.toString(),
    };
    dispatch(batchExportSupplyChainProduct(filter));
  };

  // 取得supplyChainRelationId 存進retailerProductFilter
  useEffect(() => {
    if (supplyChainRelationId) {
      const filter = {
        ...initialState.retailerProductFilter,
        supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
      };
      dispatch(updateRetailerProductFilter(filter));
    }
  }, [dispatch, supplyChainRelationId]);

  // retailerProductFilter有supplyChainRetailerProductlineRelation,才開始取得/更新列表
  useEffect(() => {
    if (retailerProductFilter.supplyChainRetailerProductlineRelation) {
      dispatch(fetchRetailerProductList(retailerProductFilter));
    }
  }, [dispatch, retailerProductFilter]);

  return (
    <Wrapper>
      <PageTitle title="供應商列表" />
      <Header>
        <Row>
          <Col>
            <Space>
              <Button type="primary" icon={<PlusCircleTwoTone />} onClick={togglePopup}>
                新增商品
              </Button>
              {openPopup && (
                <SupplyChainProductPopup
                  currentData={currentData}
                  setCurrentData={setCurrentData}
                  onClose={togglePopup}
                />
              )}
              <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
                篩選
              </Button>
              <Button onClick={toggleImportCSV}>批次匯入</Button>
              <Button onClick={handleExport}>批次匯出</Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <RetailerProductListFilter
        show={showFilter}
        form={filterForm}
        updateParams={handleOnFilterSubmit}
        onReset={handleReset}
      />
      <TableWrapper>
        <IntegratedTable
          isPagination
          paginationConfig={{
            updateFilterParams: (params) => dispatch(updateRetailerProductFilter(params)),
            filterParams: retailerProductFilter,
            totalCount: count,
          }}
          loading={isFetching}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
          columns={tableColumns}
          dataSource={retailerProductList}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSupplyChainProductSelectedIds,
          }}
          rowKey="id"
          pagination={false}
        />
      </TableWrapper>
      {showImportCSV && <ImportCSV onClose={toggleImportCSV} />}
    </Wrapper>
  );
};

export default SupplyChainProductList;

type Props = {
  show: boolean;
  form: FormInstance;
  updateParams: (params: RetailerProductListParam) => void;
  onReset: () => void;
};
const RetailerProductListFilter = (props: Props) => {
  const { show, form, updateParams, onReset } = props;

  const handleOnReset = () => {
    onReset();
  };
  const handleOnSubmit = () => {
    const values = form.getFieldsValue();
    updateParams(values);
  };
  return (
    <FilterWrapper show={show}>
      <Form colon={false} labelAlign="left" form={form} onFinish={handleOnSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="SKU" name="sku">
              <Input type="text" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="品名" name="nameQ">
              <Input type="text" allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="end">
        <Col>
          <Space>
            <Button onClick={handleOnReset}>清除篩選條件</Button>
            <Button type="primary" onClick={handleOnSubmit}>
              套用
            </Button>
          </Space>
        </Col>
      </Row>
    </FilterWrapper>
  );
};
