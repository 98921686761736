/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import brandOperationApi, { FetchVendorListParam, IntoWarehouseItem } from "@api/brandOperationApi";
import PageTitle from "@component/PageTitle";
import PopupBackground from "@component/PopupBackground";
import {
  addIWApplication,
  copyIWApplication,
  fetchIntoWarehouseList,
  invalidIWApplication,
  IWBrandState,
  resetIntoWarehouseBrandSlice,
  updateIntoWarehouseParams,
} from "@redux/brandOperation/intoWarehouseBrandSlice";
import { Button, DatePicker, Form, Input, message, Modal, Select, Table } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import cookie from "@utils/Cookie";
import { useDispatch, useSelector } from "react-redux";
import { ContentWrapper, FlexBox, Header, PageText, SelectPageSize, ModalWrapper } from "src/styles/common";
import styled from "styled-components";
import { MEMBER_TOKEN } from "@constant/StorageKeyValue";
import { RootState } from "@redux/rootReducer";
import InfiniteScrollSelect from "@component/Common/InfiniteScrollSelect";
import IntoWareHouseFilter from "./IntoWareHouseFilter";

const CRow = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;
const TableCellDisabled = styled.div`
  color: ${({ theme }) => theme.colorNeutral400};
`;

const IntoWarehouse = () => {
  const dispatch = useDispatch();
  const { isFetching, intoWarehouseList, intoWarehouseParams } = useSelector(IWBrandState);

  const { results: IWList, count: totalCount } = intoWarehouseList;

  const [page, setPage] = useState<number>(1);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showAddModel, setShowAddModel] = useState<boolean>(false);

  const [AddModalForm] = Form.useForm();

  const handleOnPageSizeChange = (value: any) => {
    dispatch(
      updateIntoWarehouseParams({
        ...intoWarehouseParams,
        limit: Number(value),
      }),
    );
  };
  const handlePageChange = (value: number) => {
    setPage(value);
    dispatch(
      updateIntoWarehouseParams({
        ...intoWarehouseParams,
        offset: intoWarehouseParams.limit * (value - 1),
      }),
    );
  };
  const onItemInvalid = (values: IntoWarehouseItem) => {
    Modal.confirm({
      title: "你確定要作廢這筆資料？",
      content: `WMS編號: ${values.stoNumber}`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(invalidIWApplication(values.id));
      },
    });
  };
  const getTdStyle = (value: string, destroyedAt: string) =>
    destroyedAt ? <TableCellDisabled>{value ?? "-"}</TableCellDisabled> : value ?? "-";

  const columns: ColumnsType<IntoWarehouseItem> = [
    {
      key: "stoNumber",
      dataIndex: "stoNumber",
      title: "WMS編號",
      align: "center",
      width: 160,
      render: (value, record) => getTdStyle(value, record.destroyedAt),
    },
    {
      key: "supplyChain",
      dataIndex: ["supplyChain", "name"],
      align: "center",
      title: "供應商名稱",
      render: (value, record) => getTdStyle(value, record.destroyedAt),
    },
    {
      key: "totalRequestQty",
      dataIndex: "totalRequestQty",
      title: "申請進貨總量(件)",
      align: "center",
      width: 140,
      render: (value, record) => getTdStyle(value, record.destroyedAt),
    },
    {
      key: "totalArrivedQty",
      dataIndex: "totalArrivedQty",
      title: "實際進貨總量(件)",
      align: "center",
      width: 140,
      render: (value, record) => getTdStyle(value, record.destroyedAt),
    },
    {
      key: "specDate",
      dataIndex: "specDate",
      title: "預期進倉日期",
      align: "center",
      width: 120,
      render: (value, record) => getTdStyle(value, record.destroyedAt),
    },
    {
      key: "stockinDate",
      dataIndex: "stockinDate",
      title: "實際進倉日期",
      align: "center",
      width: 120,
      render: (value, record) => getTdStyle(value, record.destroyedAt),
    },
    // {
    //   key: "tariffNumber",
    //   dataIndex: "tariffNumber",
    //   title: "進口報關單號",
    //   width: 120,
    //   render: (value, record) => getTdStyle(value, record.destroyedAt),
    // },
    // {
    //   key: "PurchaseOrderNo1",
    //   dataIndex: "PurchaseOrderNo1",
    //   title: "採購單號",
    //   width: 120,
    //   render: (value, record) => getTdStyle(value, record.destroyedAt),
    //   align: "center",
    // },
    {
      key: "",
      dataIndex: "",
      title: "",
      render: (_, record) => (
        <FlexBox>
          <Button
            type="link"
            onClick={() => window.open(`${window.location.href}/${record.id}`)}
            disabled={!!record.destroyedAt}
          >
            檢視
          </Button>
          {/* <Button
            type="link"
            onClick={() => {
              dispatch(copyIWApplication(record.id));
            }}
          >
            複製
          </Button> */}
          <Button type="link" onClick={() => onItemInvalid(record)} disabled={!!record.destroyedAt}>
            作廢
          </Button>
        </FlexBox>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchIntoWarehouseList());
  }, [dispatch, intoWarehouseParams]);

  useEffect(() => {
    return () => {
      dispatch(resetIntoWarehouseBrandSlice());
    };
  }, [dispatch]);

  return (
    <ContentWrapper>
      <PageTitle title="品運進倉" />
      <Header>
        <FlexBox>
          {/* <Button type="primary" icon={<PlusCircleFilled />} onClick={() => setShowAddModel(true)}>
            新增進倉單
          </Button> */}
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </FlexBox>
        <CRow>
          <PageText>{`總共${intoWarehouseList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as string)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </CRow>
      </Header>
      <IntoWareHouseFilter show={showFilter} />
      {showAddModel && (
        <AddModal
          form={AddModalForm}
          onClose={() => {
            setShowAddModel(false);
          }}
        />
      )}

      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={IWList}
        rowKey="id"
        pagination={{
          pageSize: intoWarehouseParams.limit,
          current: page,
          showSizeChanger: false,
          total: totalCount,
          onChange: handlePageChange,
        }}
      />
    </ContentWrapper>
  );
};
export default IntoWarehouse;

type AddModalProps = {
  onClose: () => void;
  form: FormInstance;
};

const AddModal = (props: AddModalProps) => {
  const { onClose, form } = props;
  const dispatch = useDispatch();
  const staffs = useSelector((state: RootState) => state.staffs.allStaffData);

  const [ownerName, setOwnerName] = useState("");

  const { userId } = cookie.getItem(MEMBER_TOKEN);

  const handleClosePopup = () => {
    onClose();
    form.resetFields();
  };

  const handleOnSubmit = () => {
    const { supplyChain, specDate } = form.getFieldsValue();
    dispatch(addIWApplication({ supplyChain, specDate: specDate.format("YYYY-MM-DD") }));
    handleClosePopup();
  };

  useEffect(() => {
    const owner = staffs.find((staff) => staff.id === userId);
    if (owner) {
      setOwnerName(owner.name);
    }
  }, [dispatch, form, staffs, userId]);

  const fetchSupplyChainList = async (searchParams: FetchVendorListParam) => {
    const response = await brandOperationApi.fetchVendorList(searchParams);
    return response;
  };

  return (
    <PopupBackground close={handleClosePopup} fixed>
      <ModalWrapper width="500px">
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          onFinish={handleOnSubmit}
          onFinishFailed={() => message.error("必填欄位未填寫")}
        >
          <Form.Item label="供應商名稱" name="supplyChain" rules={[{ required: true, message: "" }]}>
            <InfiniteScrollSelect fetchOptions={fetchSupplyChainList} showSearch allowClear />
          </Form.Item>
          <Form.Item label="開單人員" rules={[{ required: true }]}>
            <Input disabled value={ownerName} />
          </Form.Item>
          <Form.Item label="預計進倉日" name="specDate" rules={[{ required: true, message: "" }]}>
            <DatePicker style={{ width: "80%" }} />
          </Form.Item>
          <FlexBox marginTop="12px">
            <Button onClick={handleClosePopup}>取消</Button>
            <Button type="primary" htmlType="submit">
              建立
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
