/* eslint-disable @typescript-eslint/no-unused-vars */
// 主單
// - 列表(篩選) [GET] /stocktaking/manage/inventory/
// - 詳情 [GET] /stocktaking/manage/inventory/<pk>/
// - 匯入 [POST] /v1/stocktaking/manage/inventory/upload/
// - 作廢 [DELETE] /stocktaking/manage/inventory/<pk>/
// - 提交 [POST] /stocktaking/manage/inventory/<pk>/
// 商品
// - 列表 [GET] /stocktaking/manage/inventory/<pk>>/inventory-lines

import CoreAPI from "@api/CoreAPI";
import { transformAPIKeyToCamel } from "@api/utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "@api/utils/transformCamelToSnake";
import translateErrors from "@page/ThroughShipment/OnlineOrder/utils/translateErrors";
import { ListResponseType } from "src/types";

export interface InventorySheetListParams {
  limit: number;
  offset: number;
  sku?: string;
  invTakingDateAfter?: string;
  invTakingDateBefore?: string;
  warehouseId?: number;
  ordering?: string;
  warehouseCode?: string;
}
export enum InventorySheetType {
  INVENTORY = 1,
  DISPOSE = 2,
}
export interface InventorySheetItem {
  id: number;
  orderNumber: string;
  purposeType: InventorySheetType;
  warehouseName: string;
  invTakingDate: string;
  createdAt: string;
  comments: string;
  staff: string;
  submittedAt: string | null;
  destroyedAt: string | null;
}

export interface InventorySheetDetail extends InventorySheetItem {}
export interface ImportInventorySheetInfo {
  warehouseId: number;
  purposeType: InventorySheetType;
  invTakingDate: string;
  comments: string;
}

export type InventoryProductInfo = {
  id: number;
  sku: string;
  barcode: string;
  productName: string;
  qty: number;
};

const apiClient = new CoreAPI();

interface InventorySheetApi {
  fetchInventorySheetList: (params: InventorySheetListParams) => Promise<ListResponseType<InventorySheetItem>>;
  fetchInventorySheetDetail: (inventoryId: number) => Promise<InventorySheetDetail>;
  importInventorySheet: (info: ImportInventorySheetInfo, file: File) => Promise<any>;
  deleteInventorySheet: (inventoryId: number) => Promise<void>;
  SubmitInventorySheet: (inventoryId: number) => Promise<void>;
  fetchInventorySheetProducts: (params: {
    inventoryId: number;
    filter: { limit: number; offset: number };
  }) => Promise<ListResponseType<InventoryProductInfo>>;
}

const inventorySheetApi: InventorySheetApi = {
  fetchInventorySheetList: async (params: InventorySheetListParams) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/stocktaking/manage/inventory/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchInventorySheetDetail: async (inventoryId) => {
    const response = await apiClient.getData(`/stocktaking/manage/inventory/${inventoryId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  importInventorySheet: async (info, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const getPayload = transformCamelToSnake(info);
    Object.keys(getPayload).forEach((key) => {
      if (getPayload[key]) {
        formData.append(key, getPayload[key]);
      }
    });
    const response = await apiClient.postData("/stocktaking/manage/inventory/upload/", formData);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteInventorySheet: async (inventoryId) => {
    await apiClient.deleteData(`/stocktaking/manage/inventory/${inventoryId}/`, {});
  },
  SubmitInventorySheet: async (inventoryId) => {
    await apiClient.putData(`/stocktaking/manage/inventory/${inventoryId}/submit/`, {});
  },
  fetchInventorySheetProducts: async (params) => {
    const { inventoryId, filter } = params;
    const response = await apiClient.getData(
      `/stocktaking/manage/inventory/${inventoryId}/inventory-lines/`,
      transformCamelToSnake(filter),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default inventorySheetApi;
