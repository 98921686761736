import returnProductManagementApi, {
  ProductPayload,
  ReturnInvoiceInfo,
  ReturnProductAppItem,
  ReturnProductAppList,
  ReturnProductAppListParams,
  ReturnProductItem,
  UpdateAppDetailPayload,
} from "@api/sharing/ReturnProductManagementApi";
import throughShipmentApi, { RetailList } from "@api/throughShipment/throughShipmentApi";
import translateErrors from "@page/ThroughShipment/OnlineOrder/utils/translateErrors";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { ListResponseType } from "src/types";

interface IState {
  isFetching: boolean;
  returnProductAppListParams: ReturnProductAppListParams;
  returnProductAppList: ReturnProductAppList;
  returnProductAppDetail?: ReturnProductAppItem;
  returnProductList: ListResponseType<ReturnProductItem>;
  retailList?: RetailList[];
  importErrorResults?: string[];
}

const initialState: IState = {
  isFetching: false,
  returnProductAppListParams: {
    limit: 20,
    offset: 0,
  },
  returnProductAppList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    staffs: [],
  },
  returnProductAppDetail: undefined,
  returnProductList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  retailList: undefined,
  importErrorResults: undefined,
};

// 退貨單：
export const fetchReturnProductAppList = createAsyncThunk(
  "returnProductManagement/fetchReturnWarehouseList",
  async (params: ReturnProductAppListParams, thunkApi) => {
    try {
      const response = await returnProductManagementApi.fetchReturnProductAppList(params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const importReturnProductApp = createAsyncThunk(
  "returnProductManagement/importReturnProductApp",
  async (payload: File, thunkApi) => {
    try {
      const response = await returnProductManagementApi.importReturnProductApp(payload);
      message.success("匯入成功");
      thunkApi.dispatch(fetchReturnProductAppList({ limit: 20, offset: 0 }));
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      thunkApi.dispatch(updateImportErrorResults(translatedErrors));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateReturnProductApp = createAsyncThunk(
  "returnProductManagement/updateReturnProductApp",
  async (data: { returnId: number; payload: UpdateAppDetailPayload }, thunkApi) => {
    try {
      const { returnId, payload } = data;
      const response = await returnProductManagementApi.updateReturnProductApp(returnId, payload);
      thunkApi.dispatch(fetchReturnProductAppDetail(returnId));
      message.success("更新成功");
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const deleteReturnProductApp = createAsyncThunk(
  "returnProductManagement/deleteReturnProductApp",
  async (returnId: number, thunkApi) => {
    try {
      const {
        ReturnProductManagementSlice: { returnProductAppListParams },
      } = thunkApi.getState() as RootState;
      const response = await returnProductManagementApi.deleteReturnProductApp(returnId);
      thunkApi.dispatch(fetchReturnProductAppList(returnProductAppListParams));
      message.success("作廢成功");
      return response;
    } catch (error) {
      message.error("作廢失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchReturnPlatformList = createAsyncThunk(
  "returnProductManagement/fetchReturnPlatformList",
  async (payload: { contractTypes?: number[] }, thunkApi) => {
    try {
      const response = await throughShipmentApi.fetchRetailList(payload);
      // BE不會建立"Urmart線上"在通路中,所以手動加入
      response.results.push({
        id: -1,
        name: "Urmart線上",
        address: "",
        phone: "",
        region: {
          id: -1,
          name: "",
          regionType: 1,
        },
      });
      return response.results;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const backfillInvoice = createAsyncThunk(
  "returnProductManagement/backfillInvoice",
  async (data: { returnId: number; payload: ReturnInvoiceInfo }, thunkApi) => {
    try {
      const {
        ReturnProductManagementSlice: { returnProductAppListParams },
      } = thunkApi.getState() as RootState;
      const { returnId, payload } = data;
      const response = await returnProductManagementApi.backfillInvoice(returnId, payload);
      thunkApi.dispatch(fetchReturnProductAppList(returnProductAppListParams));
      message.success("開立成功");
      return response;
    } catch (error) {
      message.error("開立失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);
// 退貨單詳情：
export const fetchReturnProductAppDetail = createAsyncThunk(
  "returnProductManagement/fetchReturnProductAppDetail",
  async (returnId: number, thunkApi) => {
    try {
      const response = await returnProductManagementApi.fetchReturnProductAppDetail(returnId);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const applyReturnProductApp = createAsyncThunk(
  "returnProductManagement/applyReturnProductApp",
  async (returnId: number, thunkApi) => {
    try {
      const response = await returnProductManagementApi.applyReturnProductApp(returnId);
      thunkApi.dispatch(fetchReturnProductAppDetail(returnId));
      message.success("提交成功");
      return response;
    } catch (error) {
      message.error("提交失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);
// 退貨商品：
export const fetchReturnProductList = createAsyncThunk(
  "returnProductManagement/fetchReturnProductList",
  async (returnId: number, thunkApi) => {
    try {
      const response = await returnProductManagementApi.fetchReturnProductList(returnId);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createReturnProduct = createAsyncThunk(
  "returnProductManagement/createReturnProduct",
  async (data: { returnId: number; payload: ProductPayload }, thunkApi) => {
    try {
      const { returnId, payload } = data;
      const response = await returnProductManagementApi.createReturnProduct(returnId, payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchReturnProductList(returnId));
      return response;
    } catch (error) {
      message.error("新增失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const updateReturnProduct = createAsyncThunk(
  "returnProductManagement/updateReturnProduct",
  async (data: { returnId: number; productId: number; payload: ProductPayload }, thunkApi) => {
    try {
      const { returnId, productId, payload } = data;
      const response = await returnProductManagementApi.updateReturnProduct(returnId, productId, payload);
      thunkApi.dispatch(fetchReturnProductList(returnId));
      message.success("更新成功");
      return response;
    } catch (error) {
      message.error("更新失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const deleteReturnProduct = createAsyncThunk(
  "returnProductManagement/deleteReturnProduct",
  async (data: { returnId: number; productId: number }, thunkApi) => {
    try {
      const { returnId, productId } = data;
      const response = await returnProductManagementApi.deleteReturnProduct(returnId, productId);
      thunkApi.dispatch(fetchReturnProductList(returnId));
      message.success("刪除成功");
      return response;
    } catch (error) {
      message.error("刪除失敗");
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchProductInfo = createAsyncThunk(
  "returnProductManagement/fetchProductInfo",
  async (data: { sku: string; warehouseCode: string }, thunkApi) => {
    try {
      const { sku, warehouseCode } = data;
      const response = await returnProductManagementApi.fetchProductInfo(sku, warehouseCode);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const ReturnProductManagementSlice = createSlice({
  name: "returnProduct",
  initialState,
  reducers: {
    resetSlice: () => initialState,
    updateAppListParams: (state, action) => {
      state.returnProductAppListParams = action.payload;
    },
    resetAppListParams: (state) => {
      state.returnProductAppListParams = initialState.returnProductAppListParams;
    },
    updateImportErrorResults: (state, action) => {
      state.importErrorResults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReturnProductAppList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturnProductAppList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchReturnProductAppList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.returnProductAppList = action.payload;
    });
    builder.addCase(fetchReturnPlatformList.fulfilled, (state, action) => {
      state.retailList = action.payload;
    });
    builder.addCase(fetchReturnProductAppDetail.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturnProductAppDetail.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchReturnProductAppDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.returnProductAppDetail = action.payload;
    });
    builder.addCase(fetchReturnProductList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturnProductList.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchReturnProductList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.returnProductList = action.payload;
    });
  },
});

export const {
  resetSlice,
  updateAppListParams,
  resetAppListParams,
  updateImportErrorResults,
} = ReturnProductManagementSlice.actions;
export default ReturnProductManagementSlice.reducer;

export const returnProductState = (state: RootState) => state.ReturnProductManagementSlice;
